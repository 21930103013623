import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import history from '../../core/utils/history';
import { onlyAuthorisedAllowed } from '../../core/utils/coreRouter';
import './style.scss';

import { getAuthKey } from '../../core/utils/api';

const { VINE_DASHBOARD_URL, SSO_LOGIN_URL } = require('../../core/constants').default;

class DefaultPage extends Component {
    render() {
        const { isLoggedIn } = this.props;

        const Redirecting = () => (
            <div className="default-page-wrapper">
                <div className="default-page-inner">
                    <h1>Redirecting</h1>
                    <Spin />
                </div>
            </div>
        );

        if (isLoggedIn && getAuthKey() && onlyAuthorisedAllowed()) {
            history.push('/search');
            return <Redirecting />;
        }

        if (SSO_LOGIN_URL) {
            window.location.href = SSO_LOGIN_URL;
            return <Redirecting />;
        }

        return (
            <div className="default-page-wrapper">
                <div className="default-page-inner">
                    <h2>WSH Supplier Search Engine</h2>
                    <Button type="primary" href={VINE_DASHBOARD_URL}>
                        Log in with vine
                    </Button>
                </div>
            </div>
        );
    }
}

DefaultPage.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        isLoggedIn: state.user.isLoggedIn,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({}, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
