import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pluck } from 'underscore';

import ReactGA from 'react-ga4';
import * as helpActions from '../../core/help/helpActions';
import * as tradingRelationshipActions from '../../core/tradingRelationship/tradingRelationshipActions';
import * as priceLevelActions from '../../core/priceLevel/priceLevelActions';
import * as categoryActions from '../../core/category/categoryActions';

import DOM from './supplierRequests';

class SupplierRequests extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            activeTabKey: 'all',
            modalFormVisible: false,
            modalFormType: null,
            page: 1,
            selectedRelationship: {},
            newPriceLevel: false,
            showSortDropdown: false,
            showSupplierCodeInput: false,
            showLocationCodeInput: false,
            sortBy: 'created_at',
            sortByOrder: 'desc',
            existingSupplierCategories: [],
            updatedSupplierCategories: [],
        };
        this.formRef = React.createRef();
    }

    componentDidMount() {
        const { actions } = this.props;
        const { activeTabKey, sortBy, sortByOrder } = this.state;
        actions.getRelationshipsForSctRequest({ start: 0, status: activeTabKey, sortBy, sortByOrder });
        actions.getPriceLevelsRequest();
        actions.getCategoriesRequest();
        ReactGA.send({ hitType: 'pageview', page: '/supplier-requests', title: 'Supplier Requests' });
    }

    setModalFormVisible(modalFormVisible) {
        this.setState({ modalFormVisible });
    }

    setModalFormType(modalFormType) {
        this.setState({ modalFormType });
    }

    onTabsChange = status => {
        const { actions } = this.props;
        const { sortBy, sortByOrder } = this.state;
        this.setState({ activeTabKey: status, page: 1 });
        actions.getRelationshipsForSctRequest({ start: 0, status, sortBy, sortByOrder });
    };

    onPageChange = page => {
        const { actions } = this.props;
        const { activeTabKey, sortBy, sortByOrder } = this.state;
        this.setState({ page });
        actions.getRelationshipsForSctRequest({ start: (5 * page) - 5, status: activeTabKey, sortBy, sortByOrder });
    };

    handleActionButtonSubmit = (e, relationshipKey, action) => {
        const { relationships, actions } = this.props;

        if (this.formRef.current) {
            this.formRef.current.resetFields();
        }

        if (action === 'View') {
            window.location = `/supplier-view-request/${relationships[relationshipKey].id}`;
        } else if (action === 'report') {
            actions.generateSupplierReportRequest(relationships[relationshipKey].supplier.id);
        } else {
            this.setState({
                modalFormVisible: true,
                modalFormType: e.target ? e.target.innerText.toLowerCase() : e.key.toLowerCase(),
                selectedRelationship: relationships[relationshipKey],
                existingSupplierCategories: relationships[relationshipKey].supplier.categories,
                updatedSupplierCategories: pluck(relationships[relationshipKey].supplier.categories, 'id'),
                newPriceLevel: false,
                showSupplierCodeInput: false,
                showLocationCodeInput: false,
            });
        }
    }

    sortItems = (sortBy, sortByOrder) => {
        const { activeTabKey, page } = this.state;
        const { actions } = this.props;

        actions.getRelationshipsForSctRequest({ start: (5 * page) - 5, status: activeTabKey, sortBy, sortByOrder });
        this.setState({ sortBy, sortByOrder });

    }

    updateSupplierCategories = (supplierCategories) => {
        const combinedCategories = [];

        supplierCategories.forEach(scat => {
            if (scat.isPrimary) {
                combinedCategories.push({ catId: scat.id, isPrimary: true });
            } else {
                combinedCategories.push({ catId: scat.id });
            }
            if (scat.subcatId) {
                combinedCategories.push({ catId: scat.subcatId });
            }
        });
        this.setState({ updatedSupplierCategories: combinedCategories });
    }

    approve = ({ email, price_level }) => {
        const { actions } = this.props;
        const { selectedRelationship, updatedSupplierCategories } = this.state;

        const primary_category = updatedSupplierCategories.find(cat => cat.isPrimary).catId;
        const categories = pluck(updatedSupplierCategories.filter(cat => !cat.isPrimary), 'catId');

        actions.sctApproveRequest({
            id: selectedRelationship.id,
            price_level,
            email,
            categories,
            primary_category,
        });

        this.setState({ modalFormVisible: false });
        // call the tab to refresh results
        setTimeout(() => { this.onTabsChange(this.state?.activeTabKey); }, 900);
    }

    process = ({ price_level, new_price_level_name, new_price_level_value, supplier_code, location_code }) => {
        const { actions } = this.props;
        const { selectedRelationship } = this.state;

        actions.sctProcessRequest({
            id: selectedRelationship.id,
            price_level,
            new_price_level_name,
            new_price_level_value,
            supplier_code,
            location_code,
        });

        this.setState({ modalFormVisible: false });
        // call the tab to refresh results
        setTimeout(() => { this.onTabsChange(this.state?.activeTabKey); }, 900);
    }

    reject = ({ reason, action_required, action_owner }) => {
        const { actions } = this.props;
        const { selectedRelationship } = this.state;

        actions.sctRejectRequest({
            id: selectedRelationship.id,
            reason,
            action_required,
            action_owner,
        });

        this.setState({ modalFormVisible: false });
        // call the tab to refresh results
        setTimeout(() => { this.onTabsChange(this.state?.activeTabKey); }, 900);
    }

    render() {
        return this.view();
    }
}

SupplierRequests.defaultProps = {
    relationship: {},
};

SupplierRequests.propTypes = {
    actions: PropTypes.object.isRequired,
    relationships: PropTypes.array.isRequired,
    resultCount: PropTypes.number.isRequired,
    priceLevels: PropTypes.array.isRequired,
    allCategories: PropTypes.array.isRequired,
    relationship: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        relationships: state.tradingRelationship.relationshipsForSct.items || null,
        resultCount: state.tradingRelationship.relationshipsForSct.result_count,
        priceLevels: state.priceLevel.priceLevels || null,
        allCategories: state.category.categories,
        relationship: state.tradingRelationship.relationship,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...tradingRelationshipActions,
                ...priceLevelActions,
                ...categoryActions,
                ...helpActions,
            },
            dispatch,
        ),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SupplierRequests);
