import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_SUPPLIERS_LIST_REQUEST: null,
    GET_SUPPLIERS_LIST_SUCCESS: null,
    GET_SUPPLIERS_LIST_FAILURE: null,

    GET_TOP_SUPPLIER_CATEGORIES_REQUEST: null,
    GET_TOP_SUPPLIER_CATEGORIES_SUCCESS: null,
    GET_TOP_SUPPLIER_CATEGORIES_FAILURE: null,

    GET_SUB_SUPPLIER_CATEGORIES_REQUEST: null,
    GET_SUB_SUPPLIER_CATEGORIES_SUCCESS: null,
    GET_SUB_SUPPLIER_CATEGORIES_FAILURE: null,

    SEND_USE_SUPPLIER_FORM_REQUEST: null,
    SEND_USE_SUPPLIER_FORM_SUCCESS: null,
    SEND_USE_SUPPLIER_FORM_FAILURE: null,

    CLEAR_SUPPLIER_CATEGORIES: null,

    GET_PENDING_RELATIONSHIPS_REQUEST: null,
    GET_PENDING_RELATIONSHIPS_SUCCESS: null,
    GET_PENDING_RELATIONSHIPS_FAILURE: null,

    SORT_PENDING_RELATIONSHIPS: null,

    CREATE_SUPPLIER_DETAIL_CHANGE_REQUEST: null,
    CREATE_SUPPLIER_DETAIL_CHANGE_SUCCESS: null,
    CREATE_SUPPLIER_DETAIL_CHANGE_FAILURE: null,
});

export const {
    getSuppliersListRequest,
    getSuppliersListSuccess,
    getSuppliersListFailure,
} = createActions(
    constants.GET_SUPPLIERS_LIST_REQUEST,
    constants.GET_SUPPLIERS_LIST_SUCCESS,
    constants.GET_SUPPLIERS_LIST_FAILURE,
);

export const {
    getTopSupplierCategoriesRequest,
    getTopSupplierCategoriesSuccess,
    getTopSupplierCategoriesFailure,
} = createActions(
    constants.GET_TOP_SUPPLIER_CATEGORIES_REQUEST,
    constants.GET_TOP_SUPPLIER_CATEGORIES_SUCCESS,
    constants.GET_TOP_SUPPLIER_CATEGORIES_FAILURE,
);

export const {
    getSubSupplierCategoriesRequest,
    getSubSupplierCategoriesSuccess,
    getSubSupplierCategoriesFailure,
} = createActions(
    constants.GET_SUB_SUPPLIER_CATEGORIES_REQUEST,
    constants.GET_SUB_SUPPLIER_CATEGORIES_SUCCESS,
    constants.GET_SUB_SUPPLIER_CATEGORIES_FAILURE,
);

export const {
    sendUseSupplierFormRequest,
    sendUseSupplierFormSuccess,
    sendUseSupplierFormFailure,
} = createActions(
    constants.SEND_USE_SUPPLIER_FORM_REQUEST,
    constants.SEND_USE_SUPPLIER_FORM_SUCCESS,
    constants.SEND_USE_SUPPLIER_FORM_FAILURE,
);

export const {
    clearSupplierCategories,
} = createActions(
    constants.CLEAR_SUPPLIER_CATEGORIES,
);

export const {
    getPendingRelationshipsRequest,
    getPendingRelationshipsSuccess,
    getPendingRelationshipsFailure,
} = createActions(
    constants.GET_PENDING_RELATIONSHIPS_REQUEST,
    constants.GET_PENDING_RELATIONSHIPS_SUCCESS,
    constants.GET_PENDING_RELATIONSHIPS_FAILURE,
);

export const { sortPendingRelationships } = createActions(constants.SORT_PENDING_RELATIONSHIPS);

export const {
    createSupplierDetailChangeRequest,
    createSupplierDetailChangeSuccess,
    createSupplierDetailChangeFailure,
} = createActions(
    constants.CREATE_SUPPLIER_DETAIL_CHANGE_REQUEST,
    constants.CREATE_SUPPLIER_DETAIL_CHANGE_SUCCESS,
    constants.CREATE_SUPPLIER_DETAIL_CHANGE_FAILURE,
);
