import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApi, fetchApiAuth } from '../utils/api';

import {
    approveSupplySuccess,
    approveSupplyFailure,
    getRelationshipsForSctSuccess,
    getRelationshipsForSctFailure,
    sctApproveSuccess,
    sctApproveFailure,
    sctProcessSuccess,
    sctProcessFailure,
    sctRejectSuccess,
    sctRejectFailure,
    tradingRelationshipSuccess,
    tradingRelationshipFailure,
    generateSupplierReportSuccess,
    generateSupplierReportFailure,
} from './tradingRelationshipActions';

const {
    APPROVE_SUPPLY_REQUEST,
    GET_RELATIONSHIPS_FOR_SCT_REQUEST,
    SCT_APPROVE_REQUEST,
    SCT_PROCESS_REQUEST,
    SCT_REJECT_REQUEST,
    TRADING_RELATIONSHIP_REQUEST,
    GENERATE_SUPPLIER_REPORT_REQUEST,
} = require('./tradingRelationshipActions').constants;

function* approveSupply({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: `trading-relationship/${payload.id}/supplier-approve`,
            params: payload.formData,
        });

        yield put(approveSupplySuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(approveSupplyFailure(e.response ? e.response.data.message : e));
    }
}

function* getRelationshipsForSct({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'trading-relationship/list-sct',
            params: payload,
        });
        yield put(getRelationshipsForSctSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getRelationshipsForSctFailure(e.response ? e.response.data.message : e));
    }
}

function* sctApprove({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `trading-relationship/${payload.id}/sct-approve`,
            params: payload,
        });

        yield put(sctApproveSuccess(response));
        message.success('Success');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(sctApproveFailure(e.response ? e.response.data.message : e));
    }
}

function* sctProcess({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `trading-relationship/${payload.id}/sct-process`,
            params: payload,
        });

        yield put(sctProcessSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(sctProcessFailure(e.response ? e.response.data.message : e));
    }
}

function* sctReject({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `trading-relationship/${payload.id}/sct-reject`,
            params: payload,
        });

        yield put(sctRejectSuccess(response));
        message.success('Success');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(sctRejectFailure(e.response ? e.response.data.message : e));
    }
}

function* tradingRelationship({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `trading-relationship/${payload}/relationship`,
        });
        yield put(tradingRelationshipSuccess(response));
    } catch (e) {
        yield put(tradingRelationshipFailure(e.response ? e.response.data.message : e));
        window.location.href = '/not-found';
    }
}

function* generateSupplierReport({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'trading-relationship/generate-supplier-report',
            params: { supplier_id: payload },
        });

        yield put(generateSupplierReportSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(generateSupplierReportFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* tradingRelationshipSaga() {
    yield all([
        takeEvery(APPROVE_SUPPLY_REQUEST, approveSupply),
        takeEvery(GET_RELATIONSHIPS_FOR_SCT_REQUEST, getRelationshipsForSct),
        takeEvery(SCT_APPROVE_REQUEST, sctApprove),
        takeEvery(SCT_PROCESS_REQUEST, sctProcess),
        takeEvery(SCT_REJECT_REQUEST, sctReject),
        takeEvery(TRADING_RELATIONSHIP_REQUEST, tradingRelationship),
        takeEvery(GENERATE_SUPPLIER_REPORT_REQUEST, generateSupplierReport),
    ]);
}
