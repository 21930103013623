import React from 'react';
import PropTypes from 'prop-types';

import { CoreRouter, LogoutRoute, DefaultRoute, AuthRoute } from './utils/coreRouter';

// import UseExistingSupplier from '../pages/UseExistingSupplier';
// import ReportFoodScare from '../pages/ReportFoodScare';
// import YourRequests from '../pages/YourRequests';
import Search from '../pages/Search';
import AutoLogin from '../pages/AutoLogin';
import NoCompanyError from '../pages/NoCompanyError';
import VineError from '../pages/VineError';
import VineLogout from '../pages/VineLogout';
import DefaultPage from '../pages/DefaultPage';
import Faq from '../pages/FaqPage';
import TradingCompanyInfo from '../pages/TradingCompanyInfo';
import SupplierViewRequest from '../pages/SupplierViewRequest';
import SupplierRequests from '../pages/SupplierRequests';
import ApproveSupply from '../pages/ApproveSupply';
import ApproveSupplyConfirmation from '../pages/ApproveSupplyConfirmation';
import AuditLog from '../pages/AuditLog';

// Testing contact page
const Contact = () => <div>Contact Page</div>;

const Routes = ({ persistor }) => (
    <CoreRouter>
        {/* Routes without authentication */}
        <DefaultRoute exact path="/" component={DefaultPage} />
        <DefaultRoute exact path="/auto-login" component={AutoLogin} />
        <DefaultRoute exact path="/no-company-error" component={NoCompanyError} />
        <DefaultRoute exact path="/vine-error" component={VineError} />
        <DefaultRoute exact path="/vine-logout" component={VineLogout} />
        <DefaultRoute exact path="/approve-supply/:id/:token" component={ApproveSupply} />
        <DefaultRoute exact path="/approve-supply-confirmation" component={ApproveSupplyConfirmation} />
        <DefaultRoute exact path="/trading-company-info/:id/:token" component={TradingCompanyInfo} />

        {/* Routes where authentication is required */}
        <AuthRoute exact path="/search" component={Search} />
        {/* <AuthRoute exact path="/use-existing-supplier" component={UseExistingSupplier} /> */}
        {/* <AuthRoute exact path="/report-food-scare" component={ReportFoodScare} /> */}
        <AuthRoute exact path="/supplier-requests" component={SupplierRequests} />
        <AuthRoute exact path="/supplier-view-request/:id" component={SupplierViewRequest} />
        {/* <AuthRoute exact path="/your-requests" component={YourRequests} /> */}
        <AuthRoute exact path="/contact" component={Contact} />
        <AuthRoute exact path="/faq" component={Faq} />
        <AuthRoute exact path="/audit-log" component={AuditLog} />

        {/* Logout route */}
        <LogoutRoute exact path="/logout" persistor={persistor} />
    </CoreRouter>
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;
