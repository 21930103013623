import React, { Component } from 'react';
import { Button } from 'antd';
import ReactGA from 'react-ga4';

import './style.scss';

const {
    VINE_DASHBOARD_URL,
} = require('../../core/constants').default;

/**
 * This page allows the user to click a button to go back to vine. It should be
 * a publicly available URL
 */
class NoCompanyError extends Component {

    componentDidMount() {
        ReactGA.send({ hitType: 'pageview', page: '/no-company-error', title: 'No Company Error' });
    }

    render() {
        return (
            <div className="vine-error-container">
                <div className="centered-box">
                    <h2>You are not associated to any brands</h2>
                    <h5>Please contact your <a href="mailto:info@wshsupport.com">WSH administrator</a></h5>
                    <Button
                        type="primary"
                        className="mt-2"
                        href={VINE_DASHBOARD_URL}>
                        Go back vine
                    </Button>
                </div>
            </div>
        );
    }
}

export default NoCompanyError;
