import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga4';

import * as auditLogActions from '../../core/auditLog/auditLogActions';
import DOM from './auditLogs';

class AuditLog extends Component {

    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            page: 1,
            perPage: 10,
            sortField: '',
            sortOrder: '',
            modalVisibility: false,
            auditLog: {},
        };
        this.myRef = React.createRef();
    }

    componentDidMount() {
        const { page, perPage } = this.state;
        this.getAuditLogs(page, perPage);
        ReactGA.send({ hitType: 'pageview', page: '/audit-log', title: 'Audit Log' });
    }

    componentDidUpdate() {
        // set the form value
        const { auditLog: { before_change, after_change } } = this.state;
        if (this.myRef.current) {
            this.myRef.current.setFieldsValue({
                before_change,
                after_change,
            });
        }
    }

    getAuditLogs = () => {
        const { actions } = this.props;
        const { page, perPage, sortField, sortOrder } = this.state;
        actions.getAuditLogsRequest({ page, perPage, sortField, sortOrder });
    }

    tableChanged = (pagination, filters, sorter) => {
        let { sortField, sortOrder, page } = this.state;
        if (sorter.order !== undefined) {
            sortField = sorter.field;
            sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            page = 1;
        } else {
            sortField = '';
            sortOrder = '';
            page = 1;
        }
        this.setState({ sortField, sortOrder, page }, () => {
            this.getAuditLogs();
        });
    }

    handlePageChange = (page) => {
        this.setState({ page }, () => {
            this.getAuditLogs();
        });
    }

    openModal = (id) => {
        const { auditLogs: { data } } = this.props;
        const auditLog = data.find(x => x.id === id);
        this.setState({ modalVisibility: true, auditLog });
    };

    closeModal = () => {
        this.setState({ modalVisibility: false });
    };

    render() {
        return this.view();
    }
}

AuditLog.propTypes = {
    actions: PropTypes.object.isRequired,
    auditLogs: PropTypes.any.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        auditLogs: state.auditLog.auditLogs,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...auditLogActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLog);
