/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { updateSessionRequest } from './userSession/userSessionActions';

const SessionProvider = ({ children }) => {
    const dispatch = useDispatch();

    const lastActivity = useRef(new Date());
    const [activeTab, setActiveTab] = useState(!document.hidden);

    const activityHandler = () => {
        // Check to see if the tab is active
        if (activeTab) {
            lastActivity.current = new Date();
        }
    };

    const handleVisibilityChange = () => {
        setActiveTab(!document.hidden);
    };

    useEffect(() => {
        window.addEventListener('click', activityHandler);
        window.addEventListener('keydown', activityHandler);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('click', activityHandler);
            window.removeEventListener('keydown', activityHandler);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [activeTab]);

    useEffect(() => {
        const interval = setInterval(() => {
            const diff = new Date() - lastActivity.current;
            // If the last activity was more than 2 minutes ago, we stop the session updates
            if (diff < 2 * 60000) {
                dispatch(updateSessionRequest());
            }
        }, 20000); // Every 20 seconds

        return () => {
            clearInterval(interval);
        };
    }, [dispatch]);

    return <>{children}</>;
};

SessionProvider.propTypes = {
    children: PropTypes.node,
};

SessionProvider.defaultProps = {
    children: null,
};

export default SessionProvider;
