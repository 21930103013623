import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    UPDATE_SESSION_REQUEST: null,
    UPDATE_SESSION_SUCCESS: null,
    UPDATE_SESSION_FAILURE: null,
});

export const {
    updateSessionRequest,
    updateSessionSuccess,
    updateSessionFailure,
} = createActions(
    constants.UPDATE_SESSION_REQUEST,
    constants.UPDATE_SESSION_SUCCESS,
    constants.UPDATE_SESSION_FAILURE,
);
