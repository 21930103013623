import { all } from 'redux-saga/effects';
import userSaga from './user/userSaga';
import reportSaga from './report/reportSaga';
import chatSaga from './chat/chatSaga';
import categorySaga from './category/categorySaga';
import searchSaga from './search/searchSaga';
import supplierSaga from './supplier/supplierSaga';
import companySaga from './company/companySaga';
import requestsSaga from './requests/requestsSaga';
import helpSaga from './help/helpSaga';
import faqSaga from './faq/faqSaga';
import settingSaga from './setting/settingSaga';
import tradingRelationshipSaga from './tradingRelationship/tradingRelationshipSaga';
import auditLogSaga from './auditLog/auditLogSaga';
import priceLevelSaga from './priceLevel/priceLevelSaga';
import userSessionSaga from './userSession/userSessionSaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        userSaga(),
        reportSaga(),
        chatSaga(),
        categorySaga(),
        searchSaga(),
        supplierSaga(),
        companySaga(),
        requestsSaga(),
        helpSaga(),
        faqSaga(),
        settingSaga(),
        tradingRelationshipSaga(),
        auditLogSaga(),
        priceLevelSaga(),
        userSessionSaga(),
    ]);
}
