import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Button } from 'antd';
import Icon from '../../elements/Icon';

const { USE_SUPPLIER_FORM_LINK, UNAPPROVED_SUPPLIER_LINK } = require('../../core/constants').default;

const getUseSupplierButton = (result) => {
    if (!result.direct) {
        return null;
    }

    if (!result.is_global && result.parent_id !== null) {
        return null;
    }

    return (
        <Col span={8}>
            <a href={result?.approved ? USE_SUPPLIER_FORM_LINK : UNAPPROVED_SUPPLIER_LINK} target="_blank" rel="noopener noreferrer">
                <Button className="btn primary">Use Supplier</Button>
            </a>
        </Col>
    );
};

const SearchResult = ({ result, theKey, renderApprovalPopover, renderInfoDescription, getSupplierDetails, user, preview }) => (
    <div className="results__item" key={result.id}>
        <div className="item__title">
            <h4 className="h-4">{result.name}</h4>
            {renderApprovalPopover ? renderApprovalPopover(result, user) : ''}
        </div>
        <Row type="flex" align="middle" gutter={40} className="mt-2">
            <Col>
                <span className="item__info">
                    <Icon name="location" />
                    <span>{result.distance !== null && result.distance !== undefined ? result.distance.toString().slice(0, 4) : ''} KM</span>
                </span>
            </Col>
            <Col>
                <a href={`tel:${result.phone}`} className="link">{result.phone}</a>
            </Col>
        </Row>

        { renderInfoDescription ? renderInfoDescription(theKey, result) : (
            <div className="info__description">
                <p style={{ maxHeight: '60px' }} className="mt-2">{result.description}</p>
            </div>
        )}

        <Row type="flex" align="middle" gutter={12} className="mt-2">
            {getUseSupplierButton(result)}
            <Col span={8}>
                <a href={result.website} rel="noopener noreferrer" target="_blank"><Button disabled={!result.website} className="btn secondary">Visit Website</Button></a>
            </Col>
            <Col span={8}>
                {!preview
                    ? (
                        <Button onClick={() => getSupplierDetails(result.id, result.parent_id || null)} className="btn iconned">More info</Button>
                    ) : <></>}
            </Col>
        </Row>
    </div>
);

SearchResult.defaultProps = {
    theKey: null,
    renderApprovalPopover: null,
    renderInfoDescription: null,
    getSupplierDetails: null,
    user: null,
    preview: false,
};

SearchResult.propTypes = {
    theKey: PropTypes.any,
    renderApprovalPopover: PropTypes.func,
    renderInfoDescription: PropTypes.func,
    getSupplierDetails: PropTypes.func,
    result: PropTypes.object.isRequired,
    user: PropTypes.object,
    preview: PropTypes.bool,
};

export default SearchResult;
