import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchApiAuth } from '../utils/api';

import {
    updateSessionFailure,
    updateSessionSuccess,
} from './userSessionActions';

const {
    UPDATE_SESSION_REQUEST,
} = require('./userSessionActions').constants;

function* updateSession() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'user-session/update-session',
        });
        yield put(updateSessionSuccess(response));
    } catch (e) {
        yield put(updateSessionFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* gallerySaga() {
    yield* [
        takeEvery(UPDATE_SESSION_REQUEST, updateSession),
    ];
}
