import { Component } from 'react';
import ReactGA from 'react-ga4';
import DOM from './notFound';

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        ReactGA.send({ hitType: 'pageview', page: '/not-found', title: 'Not Found' });
    }

    render() {
        return this.view();
    }
}
export default NotFound;
