import { Record } from 'immutable';

const {
    GET_AUDIT_LOGS_REQUEST,
    GET_AUDIT_LOGS_SUCCESS,
    GET_AUDIT_LOGS_FAILURE,
} = require('./auditLogActions').constants;

const InitialState = Record({
    auditLogs: [],
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

/**
* ## galleryReducer function
* @param {Object} state - initialState
* @param {Object} action - type and payload
*/
export default function auditLogReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);
    switch (type) {
    case GET_AUDIT_LOGS_REQUEST:
        return state.set('isFetching', true).set('error', null);

    case GET_AUDIT_LOGS_SUCCESS:
        return state.set('isFetching', false).set('auditLogs', payload || []);

    case GET_AUDIT_LOGS_FAILURE:
        return state.set('isFetching', false).set('error', payload);

    default:
        return state;

    }
}
