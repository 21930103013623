import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getPriceLevelsSuccess,
    getPriceLevelsFailure,
} from './priceLevelActions';

const {
    GET_PRICE_LEVELS_REQUEST,
} = require('./priceLevelActions').constants;

function* getPriceLevels() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'price-level/all-active',
        });

        yield put(getPriceLevelsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getPriceLevelsFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* priceLevelSaga() {
    yield all([
        takeEvery(GET_PRICE_LEVELS_REQUEST, getPriceLevels),
    ]);
}
