import React from 'react';
import { Layout } from 'antd';
import ReactGA from 'react-ga4';

const ApproveSupplyConfirmation = () => {

    ReactGA.send({ hitType: 'pageview', page: '/approve-supply-confirmation', title: 'Approve Supply Confirmation' });

    return (
        <Layout
            title={<div>Thank you</div>}>
            <div className="content-wrap" style={{ minHeight: '960px' }}>
                <div className="content-contr">
                    <div className="content__block mt-5">
                        You have have successfully processed your approval list.
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ApproveSupplyConfirmation;
