import { call, put, takeEvery, all } from 'redux-saga/effects';
import { fetchApiAuth } from '../utils/api';

import { getAuditLogsSuccess, getAuditLogsFailure } from './auditLogActions';

const { GET_AUDIT_LOGS_REQUEST } = require('./auditLogActions').constants;

function* getAuditLogs({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/auditLog',
            params: payload,
        });

        yield put(getAuditLogsSuccess(response.data));
    } catch (e) {
        yield put(getAuditLogsFailure(e.response ? e.response.data : e));
    }
}

export default function* AuditLogsSaga() {
    yield all([takeEvery(GET_AUDIT_LOGS_REQUEST, getAuditLogs)]);
}
