/* eslint-disable react/sort-comp */
/* global sessionStorage */
/* global document: true */
import React, { Component } from 'react';
import { notification, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { find, isEmpty, isEqual } from 'underscore';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import ReactGA from 'react-ga4';

import DOM from './search';

import * as chatActions from '../../core/chat/chatActions';
import * as userActions from '../../core/user/userActions';
import * as categoryActions from '../../core/category/categoryActions';
import * as searchActions from '../../core/search/searchActions';
import * as settingActions from '../../core/setting/settingActions';
import * as userSessionActions from '../../core/userSession/userSessionActions';

import * as helpActions from '../../core/help/helpActions';

const { MAP_STYLES } = require('../../core/utils/values').default;

const {
    API_URL,
    ADD_SUPPLIER_FORM_LINK,
} = require('../../core/constants').default;

const mapIconsDir = `${API_URL.slice(0, -4)}/storage/map-icons/`;

class Search extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            locationModalVisible: false,
            showChat: false,
            chatLog: {},
            value: false,
            mapCircles: [],
            maps: false,
            supplierAreasVisible: false,
            isMobile: false,
            showSearch: true,
            pinsLoaded: false,
            infoWindows: {},
            allMarkersCluster: null,
            allMarkers: null,
            findNearestPin: false,
            hasShownUserCompany: false,
        };
    }

    componentDidMount() {
        const { actions, currentLocation, defaultLocation, selectedCompanyId, searchParams } = this.props;
        const { category, name, companyId } = searchParams;
        if (window.innerWidth < 768 || window.screen.width < 768) {
            this.setState({ isMobile: true, showSearch: false });
        }

        window.addEventListener('resize', this.handleResize);

        let location = null;
        if (currentLocation) {
            location = {
                lat: currentLocation.coordinates.lat,
                lng: currentLocation.coordinates.lng,
            };
        } else {
            location = {
                lat: defaultLocation?.coordinates.lat,
                lng: defaultLocation?.coordinates.lng,
            };
        }

        actions.getCategoriesRequest();
        actions.getPinsRequest({
            category,
            name,
            companyId: selectedCompanyId || companyId,
            latitude: location.lat,
            longitude: location.lng,
            radius: 6371,
        });

        actions.getResultsRequest({
            start: 0,
            finish: 10,
            companyId: selectedCompanyId || companyId,
            latitude: location.lat,
            longitude: location.lng,
            radius: 6371,
        });

        ReactGA.send({ hitType: 'pageview', page: '/search', title: 'Search' });

        actions.updateSessionRequest();

    }

    componentDidUpdate(prevProps) {
        const { maps, pinsLoaded, findNearestPin } = this.state;
        const { pins: prevPins } = prevProps;
        const { pins, currentLocation, defaultLocation } = this.props;
        if ((maps && !pinsLoaded && !isEmpty(pins)) || (maps && !isEqual(prevPins, pins))) {
            this.pushSupplierRequestCompanyPin();
            this.showAllPins();
        }

        if (maps && !isEmpty(pins) && maps && !isEqual(prevPins, pins) && findNearestPin) {
            let location = {};
            if (currentLocation) {
                location = currentLocation;
            } else {
                location = defaultLocation;
            }
            this.findNearestPin(pins, location);
        }

        if (maps) {
            this.showSupplierRequestCompany();
        }
    }

    showSupplierRequestCompany = () => {
        const { company } = this.props;
        const { hasShownUserCompany } = this.state;

        if (company && !hasShownUserCompany) {
            this.state.map.setCenter({ lat: parseFloat(company.latitude), lng: parseFloat(company.longitude) });
            this.setState({ hasShownUserCompany: true });
        }
    }

    pushSupplierRequestCompanyPin = () => {
        const { company, pins } = this.props;

        if (company) {
            const pinAlreadyAdded = pins.some(pin => pin.id === company.id);

            if (!pinAlreadyAdded) {
                pins.push({
                    id: company.id,
                    is_global: 0,
                    latitude: company.latitude,
                    longitude: company.longitude,
                    name: company.title,
                    parent_id: null,
                    phone: company.phone,
                    website: null,
                });
            }
        }
    }

    findNearestPin = (pins, currentLocation) => {
        let lowestDistance = null;
        let nearestPin = {};
        let currentDistance = null;

        pins.forEach(pin => {
            currentDistance = this.haversineDistance(currentLocation.coordinates, { lat: parseFloat(pin.latitude), lng: parseFloat(pin.longitude) });
            if (!lowestDistance || currentDistance < lowestDistance) {
                lowestDistance = currentDistance;
                nearestPin = pin;
            }
        });

        this.state.map.setCenter({ lat: parseFloat(nearestPin.latitude), lng: parseFloat(nearestPin.longitude) });
        this.setState({ findNearestPin: false });
    }

    activateFindNearestPin = () => {
        this.setState({ findNearestPin: true });
    }

    haversineDistance = (mk1, mk2) => {
        const R = 3958.8; // Radius of the Earth in miles
        const rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
        const rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
        const difflat = rlat2 - rlat1; // Radian difference (latitudes)
        const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

        const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
        return d;
    }

    handleResize = () => {
        if (window.innerWidth < 768 || window.screen.width < 768) {
            this.setState({ isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }
    }

    openCantAddSupplierNotification = () => {
        const { cannotAddReason } = this.props;
        notification.open({
            key: 'unique_id',
            closeIcon: ' ',
            description: (
                <div className="notif">
                    <h4 className="h-4">Adding a new suppliers is temporarily suspended</h4>
                    <p className="mt-1">
                        {cannotAddReason}
                    </p>
                    <div className="mt-3">
                        <Button className="primary middle" onClick={this.closeNotif}>
                            Ok
                        </Button>
                    </div>
                </div>
            ),
        });
    };

    openAddSupplierNotification = () => {
        notification.open({
            key: 'unique_id',
            closeIcon: ' ',
            description: (
                <div className="notif">
                    <h4 className="h-4">Request to use a new supplier</h4>
                    <p className="mt-1">
                        If you cannot locate the supplier you are looking for or you would like to start trading with a new supplier you will need to complete our supplier onboarding
                        <a target="_blank" rel="noopener noreferrer" href={ADD_SUPPLIER_FORM_LINK}> form</a>.
                    </p>
                    <p>
                        This will be sent to our purchasing team for review before the supplier is invited to complete their information and be setup on our systems.
                    </p>
                    <a target="_blank" rel="noopener noreferrer" href={ADD_SUPPLIER_FORM_LINK}>Go to form</a>
                    <div className="mt-3">
                        <Button className="primary middle" onClick={this.closeNotif}>
                            Close
                        </Button>
                    </div>
                </div>
            ),
        });
    };

    closeNotif = () => {
        notification.close('unique_id');
    };

    closeLocationModal = () => {
        sessionStorage.setItem('sessionInUse', true);
        this.setState({ locationModalVisible: false });
    };

    showLocationModal = () => this.setState({ locationModalVisible: true });

    handleApiLoaded = (map, maps) => {
        this.setState({
            map,
            maps,
        });
    };

    centerMap = () => {
        const { map } = this.state;
        const { currentLocation, defaultLocation } = this.props;
        if (currentLocation) {
            const { coordinates } = currentLocation;
            map.setCenter({ lat: coordinates.lat, lng: coordinates.lng });
        } else {
            const { coordinates } = defaultLocation;
            map.setCenter({ lat: coordinates.lat, lng: coordinates.lng });
        }
    }

    getSupplierDetails = (supplierId, parentId) => {
        const { actions, searchParams: { latitude, longitude }, selectedCompanyId } = this.props;

        actions.hideResultsSidebar();
        actions.showSupplierDetails();
        actions.getSupplierRequest({ id: supplierId, params: { latitude, longitude, requestingCompany: selectedCompanyId } });
        if (parentId) {
            actions.getSupplierParentRequest({ id: parentId, params: { latitude, longitude } });
        }
    }

    getMarker = (tier, approved, global, depot, indirect) => {
        if (tier) {
            if (tier === '1' || tier === '2') {
                if (approved) {
                    return `${mapIconsDir}/star-marker.png`;
                }
                return `${mapIconsDir}/green-marker.png`;
            } if (tier === '2.1') {
                return `${mapIconsDir}/not-approved-marker.svg`;
            }
        }

        if (depot) {
            return `${mapIconsDir}/depot-marker.svg`;
        }
        if (indirect) {
            return `${mapIconsDir}/indirect-marker.svg`;
        }
        if (approved) {
            return `${mapIconsDir}/green-marker.png`;
        }
        if (global) {
            return `${mapIconsDir}/star-marker.png`;
        }

        return `${mapIconsDir}/not-approved-marker.svg`;
    };

    getInfoWindows = (markers) => {
        const { maps, map } = this.state;

        // eslint-disable-next-line array-callback-return
        markers.map(marker => {
            const infoWindow = new maps.InfoWindow({
                content: `
                    <div style="cursor: pointer" id="info-window-${marker.supplierId}">
                        <h1>${marker.supplierName}</h1>
                        <div class="iw-divider"></div>
                        <div style="margin-bottom: 10px;"><a href="${marker.supplierWebsite || ''}">${marker.supplierWebsite || ''}</a></div>
                        <div><a href="tel:${marker.supplierPhone || ''}"}>${marker.supplierPhone || ''}</a></div>
                    </div>`,
            });

            marker.addListener('mouseover', () => {
                infoWindow.open(map, marker);
            });

            let timeout = false;
            marker.addListener('mouseout', () => {
                timeout = setTimeout(() => infoWindow.close(map), 600);
            });

            marker.addListener('click', () => {
                this.getSupplierDetails(marker.supplierId, marker.supplierParentId || null);
            });

            infoWindow.addListener('domready', () => {
                document.getElementById(`info-window-${marker.supplierId}`).addEventListener('mouseover', () => {
                    clearTimeout(timeout);
                });

                document.getElementById(`info-window-${marker.supplierId}`).addEventListener('mouseleave', () => {
                    infoWindow.close(map);
                });

                document.getElementById(`info-window-${marker.supplierId}`).addEventListener('click', () => {
                    this.getSupplierDetails(marker.supplierId, marker.supplierParentId || null); ///
                });
            });
        });
    }

    resetPins = () => {
        const { actions, currentLocation, defaultLocation, selectedCompanyId, searchParams } = this.props;
        const { companyId } = searchParams;

        let location = null;
        if (currentLocation) {
            location = {
                lat: currentLocation.coordinates.lat,
                lng: currentLocation.coordinates.lng,
            };
        } else {
            location = {
                lat: defaultLocation.coordinates.lat,
                lng: defaultLocation.coordinates.lng,
            };
        }

        actions.getPinsRequest({
            companyId: selectedCompanyId || companyId,
            latitude: location.lat,
            longitude: location.lng,
            radius: 6371,
        });
    }

    showAllPins = () => {
        const { maps, map, allMarkersCluster } = this.state;
        const { pins } = this.props;

        if (allMarkersCluster) {
            allMarkersCluster.clearMarkers();
        }

        let markers = [];

        markers = pins.map(pin => {
            const marker = new maps.Marker({
                position: { lat: parseFloat(pin.latitude), lng: parseFloat(pin.longitude) },
                map,
                icon: this.getMarker(pin.tier, pin.approved, pin.is_global, pin.parent_id, !pin.direct),
                supplierName: pin.name,
                supplierWebsite: pin.website,
                supplierPhone: pin.phone,
                supplierId: pin.id,
                supplierParentId: pin.parent_id,
            });
            return marker;
        });

        // eslint-disable-next-line no-unused-vars
        const markerCluster = new MarkerClusterer(map, markers, { imagePath: `${mapIconsDir}/m` });

        this.getInfoWindows(markers);

        this.setState({ pinsLoaded: true, allMarkersCluster: markerCluster });
    }

    showSupplierAreas = () => {
        const { supplier, pins } = this.props;
        const { maps, map, supplierAreasVisible, allMarkersCluster } = this.state;
        const mapCircles = [];
        if (!supplierAreasVisible) {

            this.setState({ supplierAreasVisible: true });

            if (allMarkersCluster) {
                allMarkersCluster.clearMarkers();
            }

            const markers = [];

            const chosenSupplier = pins.find(pin => pin.id === supplier.id);

            markers.push(
                new maps.Marker({
                    position: { lat: parseFloat(chosenSupplier.latitude), lng: parseFloat(chosenSupplier.longitude) },
                    map,
                    icon: this.getMarker(chosenSupplier.tier, chosenSupplier.approved, chosenSupplier.is_global),
                    supplierName: chosenSupplier.name,
                    supplierWebsite: chosenSupplier.website,
                    supplierPhone: chosenSupplier.phone,
                    supplierId: chosenSupplier.id,
                    supplierParentId: chosenSupplier.parent_id,
                }),
            );

            this.getInfoWindows(markers);

            supplier.supplier_areas.map(sa => mapCircles.push(
                new maps.Circle({
                    strokeColor: '#27c9e5',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#27c9e5',
                    fillOpacity: 0.3,
                    map,
                    center: { lat: parseFloat(sa.latitude), lng: parseFloat(sa.longitude) },
                    radius: parseInt(sa.radius, 10) * 1000,
                }),
            ));
            this.setState({ mapCircles });
        }
    }

    backToSearch = () => {
        const { actions } = this.props;
        const { mapCircles } = this.state;
        mapCircles.map(ms => (
            ms.setMap(null)
        ));
        this.setState({ mapCircles: [], supplierAreasVisible: false });

        actions.clearSupplierDetails();
        actions.hideSupplierDetails();
        actions.showResultsSidebar();
    }

    toggleChat = () => {
        const { actions } = this.props;
        const { mapCircles } = this.state;
        if (!this.state.showChat) {
            this.setState({ showChat: true });
            actions.hideResultsSidebar();
            actions.hideSupplierDetails();
            mapCircles.map(ms => (
                ms.setMap(null)
            ));
        } else {
            this.setState({ showChat: false, searchActive: true });
        }
    }

    handleEnd = ({ steps }) => {
        const { actions, selectedCompanyId, userDetails } = this.props;
        const { name, phone_number, email, job_title } = userDetails;
        const chatLog = {};

        // eslint-disable-next-line array-callback-return
        steps.map(step => {
            chatLog[step.id] = {
                user: step.metadata !== undefined && step.metadata.isUser ? 'user' : 'bot',
                message: step.message,
            };
        });

        // TODO - Update values with real data when available
        const data = {
            company_id: selectedCompanyId,
            name,
            phone_number,
            email,
            job_title: job_title || '',
            status: 0,
            chat_log: JSON.stringify(chatLog),
        };

        actions.createChatRequest(data);
    }

    createMapOptions = () => ({
        styles: MAP_STYLES,
    });

    checkAddSupplierAccess = () => {
        const { canAdd, searchParams, disabledCategories, categories } = this.props;
        const { category } = searchParams;

        const cat = categories.find(c => c.id === category);
        let parent = null;
        if (cat && cat.parent_id) {
            parent = categories.find(p => p.id === cat.parent_id).id;
        }

        let userCanAddCategory = true;
        if (category) {
            // A category is selected, check if the selected category is in the disabled list
            const childDisabled = disabledCategories.includes(category);
            const parentDisabled = disabledCategories.includes(parent);
            userCanAddCategory = !parentDisabled && !childDisabled;
        } else {
            // If no category is selected, use the company default
            userCanAddCategory = canAdd;
        }

        if (!userCanAddCategory) {
            this.openCantAddSupplierNotification();
        } else {
            this.openAddSupplierNotification();
            // this.toggleChat();
        }
    }

    clearPage = () => {
        const { actions } = this.props;
        actions.clearSearch();
        actions.hideResultsSidebar();
        actions.hideSupplierDetails();
    }

    resetSearch = () => {
        const { actions, selectedCompanyId, currentLocation } = this.props;
        const { lat, lng } = currentLocation.coordinates;
        actions.clearSearch();
        actions.hideResultsSidebar();
        actions.clearSearch();
        actions.getResultsRequest({
            start: 0,
            finish: 10,
            companyId: selectedCompanyId,
            latitude: lat,
            longitude: lng,
            radius: 6371,
        });
        this.resetPins();
        this.centerMap();
    }

    updatePins = () => {
        const { actions, selectedCompanyId, currentLocation } = this.props;

        const location = {
            lat: currentLocation.coordinates.lat,
            lng: currentLocation.coordinates.lng,
        };

        actions.getPinsRequest({
            companyId: selectedCompanyId,
            latitude: location.lat,
            longitude: location.lng,
            radius: 6371,
        });
    }

    showSearchForm = () => {
        this.setState({ showSearch: true });
    }

    hideSearchForm = () => {
        this.setState({ showSearch: false });
    }

    downloadGuide = () => {
        const { actions } = this.props;

        actions.getGuideRequest();
    }

    render() {
        return this.view();
    }
}

Search.defaultProps = {
    defaultLocation: null,
    currentLocation: null,
    selectedCompanyId: null,
    searchParams: {},
    disabledCategories: [],
    company: null,
};

Search.propTypes = {
    actions: PropTypes.object.isRequired,
    defaultLocation: PropTypes.object,
    currentLocation: PropTypes.object,
    supplier: PropTypes.object.isRequired,
    canAdd: PropTypes.number.isRequired,
    disabledCategories: PropTypes.array,
    cannotAddReason: PropTypes.string.isRequired,
    userLatLng: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    selectedCompanyId: PropTypes.number,
    searchParams: PropTypes.object,
    categories: PropTypes.array.isRequired,
    pins: PropTypes.array.isRequired,
    company: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    const { userData } = state.user;

    let selectedCompanyId = null;
    let canAdd = false;
    let cannotAddReason = 'WSH has temporarily suspended the ability for you to use new suppliers as a result of COVID-19';
    if (userData && userData.available_companies) {
        const company = find(userData.available_companies, c => c.id === userData.active_company_id);
        if (company) {
            const parent = find(userData.available_companies, c => c.id === company.parent_id);
            selectedCompanyId = parent ? parent.id : company.id;
            if (parent) {
                canAdd = parent.can_add;
                cannotAddReason = parent.can_add_error ? parent.can_add_error : cannotAddReason;
            } else {
                canAdd = company.can_add;
                cannotAddReason = company.can_add_error ? company.can_add_error : cannotAddReason;
            }
        }
    }

    let company = null;

    if (ownProps.location.search && !isEmpty(state.tradingRelationship.relationshipsForSct)) {
        const urlParams = new URLSearchParams(ownProps.location.search);
        const relationships = state.tradingRelationship.relationshipsForSct;

        if (urlParams.has('companyid')) {
            const id = urlParams.get('companyid');
            company = relationships.items.find(rel => rel.company.id.toString() === id).company;
        }
    }

    return {
        defaultLocation: userData && userData.location,
        currentLocation: state.user.mapLocation,
        categories: state.category.categories,
        pins: state.search.pins,
        resultsSidebar: state.search.resultsSidebar,
        showSupplierDetails: state.search.showSupplierDetails,
        supplier: state.search.supplier,
        searchParams: state.search.searchParams,
        resultCount: state.search.resultCount,
        userLatLng: state.user.mapLocation && state.user.mapLocation.coordinates,
        userDetails: state.user.userData,
        canAdd,
        disabledCategories: state.user.userData.disabled_categories,
        cannotAddReason,
        selectedCompanyId,
        company,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
                ...chatActions,
                ...categoryActions,
                ...searchActions,
                ...helpActions,
                ...settingActions,
                ...userSessionActions,
            },
            dispatch,
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
