import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import lockr from 'lockr';
import { isEmpty } from 'underscore';

import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {
    loginSuccess,
    loginFailure,

    logoutSuccess,
    logoutFailure,
    saveTokenSuccess,

    getUserDataSuccess,
    getUserDataFailure,

    updateActiveCompanySuccess,
    updateActiveCompanyFailure,

    updateLocationSuccess,
    updateLocationFailure,

    setLocation,
} from './userActions';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const {
    SAVE_TOKEN_REQUEST,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    GET_USER_DATA_REQUEST,
    UPDATE_ACTIVE_COMPANY_REQUEST,
    UPDATE_LOCATION_REQUEST,
} = require('./userActions').constants;

function* saveSessionToken(action) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.payload.auth_token);
    yield put(saveTokenSuccess());
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/login',
            body: { username: action.payload.username, password: action.payload.password },
        });
        yield call(saveSessionToken, response);
        yield put(loginSuccess(response));
        history.push('/dashboard');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(loginFailure(e.response ? e.response.data.message : e));
    }
}

function* logoutRequest() {
    try {
        yield call(fetchApiAuth, {
            method: 'GET',
            url: 'user-session/update-session',
        });
        yield put(logoutSuccess());
        window.location.href = '/logout';
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(logoutFailure(e.response ? e.response.data.message : e));
        window.location.href = '/logout';
    }

}

/**
 * Fetches user data and redirects to home page on success. Should only be used
 * for the auto-login feature as upon any error it will redirect to the
 * vine-error page
 */
function* getUserData() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/user/user-data',
        });

        if (response.data && (isEmpty(response.data.available_companies) || !response.data.active_company_id)) {
            // Set data
            yield put(getUserDataSuccess(response));
            // Show user error page
            history.push('/no-company-error');
        } else {
            yield put(getUserDataSuccess(response));
            yield put(setLocation(response.data.location));
            history.push('/search');
        }
    } catch (e) {
        history.replace('/vine-error');
        yield put(getUserDataFailure(e.response ? e.response.data.message : e));
    }
}

function* updateActiveCompany(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user/change-active-company',
            body: action.payload,
        });

        yield put(updateActiveCompanySuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateActiveCompanyFailure(e.response ? e.response.data.message : e));
    }
}

function* updateLocation(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user/location',
            body: action.payload,
        });

        yield put(updateLocationSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateLocationFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(SAVE_TOKEN_REQUEST, saveSessionToken),
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(GET_USER_DATA_REQUEST, getUserData),
        takeEvery(UPDATE_ACTIVE_COMPANY_REQUEST, updateActiveCompany),
        takeEvery(UPDATE_LOCATION_REQUEST, updateLocation),
    ]);
}
