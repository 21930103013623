import React from 'react';
import {
    Tabs,
    Pagination,
    Spin,
    Row,
    Col,
    Space,
    Button,
    Dropdown,
    Menu,
    Modal,
    Form,
    Input,
    Select,
    Radio,
    DatePicker,
    Checkbox,
} from 'antd';
import moment from 'moment';
import UpdateSupplierCategories from '../../components/UpdateSupplierCategories';
import Layout from '../../components/layout/Layout';
import './style.scss';
import Icon from '../../elements/Icon';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

export default function () {
    const { resultCount, priceLevels, relationships, allCategories, user } = this.props;
    const { page, selectedRelationship, newPriceLevel, showSortDropdown, showSupplierCodeInput, showLocationCodeInput, existingSupplierCategories } = this.state;

    const rawUserRole = (user.userData && user.userData.role && user.userData.role.name) || 'user';
    const userRole = rawUserRole.toLowerCase();
    if (userRole !== 'purchasing manager' && userRole !== 'supply chain manager' && userRole !== 'admin') {
        return 'Not authorized';
    }

    const loading = false;
    const requests = [
        {
            id: '001',
            type: 'pending',
            label: 'Pending Processing',
        },
        {
            id: '002',
            type: 'pending-supplier',
            label: 'Pending Supplier',
        },
        {
            id: '003',
            type: 'pending-approval',
            label: 'Pending Approval',
        },
        {
            id: '005',
            type: 'approved',
            label: 'Approved',
        },
        {
            id: '006',
            type: 'rejected',
            label: 'Rejected',
        },
    ];

    const supplierAndLocationCodes = [
        { id: '001',
            title: 'Supplier',
            value: [selectedRelationship?.supplier?.creditor_id],
            name: 'supplier_code',
            showState: showSupplierCodeInput,
            onClick: () => this.setState({ showSupplierCodeInput: true }),
        },
        { id: '002',
            title: 'Location',
            value: [selectedRelationship?.company?.location_number],
            name: 'location_code',
            showState: showLocationCodeInput,
            onClick: () => this.setState({ showLocationCodeInput: true }),
        },
    ];

    const toolbar = [
        { label: 'Help', iconName: 'question', onClick: this.props.actions.openHelpModal },
        { label: 'Sort', iconName: 'sort', onClick: () => this.setState({ showSortDropdown: true }) },
    ];
    const navigation = [{ label: 'Supply Chain Tools' }, { label: 'Use Supplier Requests' }];

    const statusText = (statusString) => {
        if (
            statusString === 'Submitted'
            && (userRole === 'purchasing manager' || userRole === 'admin' || userRole === 'supply chain manager')
        ) {
            return 'Pending Processing';
        }

        return statusString;
    };

    const tabsList = [
        { key: 'all', title: 'All' },
        { key: 'submitted', title: statusText('Submitted') },
        { key: 'pending_supplier', title: 'Pending Supplier' },
        { key: 'pending_approval', title: 'Pending Approval' },
        { key: 'approved', title: 'Approved' },
        { key: 'rejected', title: 'Rejected' },
    ];

    const toKebabCase = str => (
        str && str
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(x => x.toLowerCase()).join('-')
    );

    const isPendingApproval = (request) => {
        const status = toKebabCase(request.status_string);

        if (status === 'pending-approval') {
            return true;
        }

        return false;
    };

    const userCanApprove = (request) => {
        if (request.requires_pm_approval === 0 || userRole === 'purchasing manager' || userRole === 'admin') {
            return true;
        }

        return false;
    };

    const actionButtonText = (request) => {
        const status = toKebabCase(request.status_string);

        if (status === 'submitted') {
            return 'Process';
        } if (status === 'pending-approval' && userCanApprove(request)) {
            return 'Approve';
        }
        return 'View';
    };

    const sortByValues = [
        {
            label: 'Date requested (ascending)',
            value: 'created_at',
            order: 'asc',
        },
        {
            label: 'Date requested (descending)',
            value: 'created_at',
            order: 'desc',
        },
        {
            label: 'Date supply should start (ascending)',
            value: 'start_date',
            order: 'asc',
        },
        {
            label: 'Date supply should start (descending)',
            value: 'start_date',
            order: 'desc',
        },
    ];

    return (
        <Layout
            title={<div>Welcome</div>}
            showMenu
            toolbar={toolbar}
            headerTitle="Your requests"
            floatingHeader
            breadcrumb={navigation}>
            {showSortDropdown ? (
                <div className="sortby-dropdown"
                    onMouseLeave={() => this.setState({ showSortDropdown: false })}>
                    {sortByValues.map(item => (
                        <option key={item.label}
                            onClick={() => {
                                this.sortItems(item.value, item.order);
                                this.setState({ showSortDropdown: false });
                            }}
                            value={item.value}>{item.label}
                        </option>
                    ))}
                </div>
            ) : ''}

            <div className="tabs-wrap">
                <div className="bg_palegrey">
                    <div className="tabs-contr">
                        <div style={{ height: '56px' }} />
                        <h1 className="h-2">Manage Use Existing Supplier Requests</h1>
                    </div>
                </div>
                <Spin spinning={loading}>
                    <Tabs
                        onChange={this.onTabsChange}
                        animated={false}
                        activeKey={this.state.activeTabKey}
                        className="tabs-custom">
                        {tabsList.map(pane => (
                            <TabPane tab={pane.title} key={pane.key}>
                                <div style={{ height: '40px' }} />
                                {relationships && relationships.map((request, idx) => (
                                    <div className="card" key={request.id}>
                                        <Row type="flex" gutter={24}>
                                            <Col span={7}>
                                                <h5 className="h-5">Requester</h5>
                                                <p className="mt-1">{request.company_name}</p>
                                                {request?.company?.latitude && request?.company?.longitude ? (
                                                    <Space size={4} align="center" className="mt-1">
                                                        <Icon name="location" className="fz-10" />
                                                        <a href={`/search?companyid=${request.company.id}`} className="link">
                                                            {request.company.location_name || 'View on map'}
                                                        </a>
                                                    </Space>
                                                ) : null}
                                                <p className="mt-1">Added {moment(request.created_at).format('DD-MM-YYYY H:m')}</p>
                                            </Col>
                                            <Col span={7}>
                                                <h5 className="h-5">Supplier</h5>
                                                <p className="mt-1">{request.supplier_name}</p>
                                                <p className="mt-1">
                                                    <a href={request.supplier_site} className="link" target="_blank" rel="noopener noreferrer">
                                                        {request.supplier_site}
                                                    </a>
                                                </p>
                                            </Col>
                                            <Col span={5}>
                                                <h5 className="h-5">Additional information</h5>
                                                <p className="mt-1">Account #: {request.supplier_account_number || 'Not Specified'}</p>
                                                <p className="mt-1">Price level: {request.price_level_value || 'Not Specified'}</p>
                                                <p className="mt-1">Start date: {moment(request.start_date).format('DD-MM-YYYY')}</p>
                                                <p className="mt-1">End date: {request.end_date ? moment(request.end_date).format('DD-MM-YYYY') : 'Not Specified'}</p>
                                            </Col>
                                            <Col span={5} className="text-right">
                                                <Button className={`bordered middleplus ${toKebabCase(request.status_string)}`}>
                                                    {statusText(request.status_string)}
                                                </Button>
                                                {isPendingApproval(request) && !userCanApprove(request) && (
                                                    <Space
                                                        size={8}
                                                        align="start"
                                                        className="mt-3  fz-14 text-left c_pumpkin"
                                                        style={{ maxWidth: 156 }}>
                                                        <span style={{ marginTop: 2 }}>
                                                            <Icon name="attention" />
                                                        </span>

                                                        <p>This request can only be approved by a purchasing manager</p>
                                                    </Space>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row
                                            type="flex"
                                            justify="space-between"
                                            align="bottom"
                                            gutter={24}
                                            className="mt-2">
                                            <Col>
                                                <Space size={8} align="end">
                                                    <h5 className="h-5">Supply Start</h5>
                                                    <Icon name="calendar" className="fz-16" />
                                                    <p>{moment(request.start_date).format('DD-MM-YYYY')} {moment(request.start_date).isSameOrBefore() ? '(already started)' : ''} </p>
                                                </Space>
                                            </Col>
                                            <Col>
                                                <Dropdown.Button
                                                    onClick={e => this.handleActionButtonSubmit(e, idx, actionButtonText(request))}
                                                    overlay={(
                                                        <Menu className="dropdown-menu">
                                                            {[
                                                                { key: 'report', label: 'Send supplier TR CSV' },
                                                                { key: 'approve', label: 'Approve', disabled: (isPendingApproval(request) && !userCanApprove(request)) },
                                                                { key: 'reject', label: 'Reject' },
                                                                { key: 'View', label: 'View' },
                                                            ].map(it => (
                                                                <Menu.Item
                                                                    key={it.key}
                                                                    onClick={(e) => {
                                                                        this.setModalFormVisible(true);
                                                                        this.setModalFormType(it.key);
                                                                        this.handleActionButtonSubmit(e, idx, it.key);
                                                                    }}
                                                                    disabled={it.disabled}>
                                                                    {it.label}
                                                                </Menu.Item>
                                                            ))}
                                                        </Menu>
                                                    )}
                                                    size="large"
                                                    icon={<Icon name="chevron-down" className="c_blue" />}
                                                    className={`dropdown-button ${actionButtonText(request) !== 'View'
                                                        ? 'is-filled'
                                                        : ''
                                                    }`}>
                                                    {actionButtonText(request)}
                                                </Dropdown.Button>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                {requests.length > 0 ? null : (
                                    <div className="no-results-wrapper">
                                        <div className="no-results-inner">
                                            <p>No requests found</p>
                                        </div>
                                    </div>
                                )}
                            </TabPane>
                        ))}
                    </Tabs>
                    <div className="tabs-contr">
                        <Pagination
                            className="pagination-custom"
                            current={page}
                            total={resultCount}
                            onChange={this.onPageChange}
                            showSizeChanger={false}
                            defaultPageSize={5} />
                    </div>
                </Spin>
            </div>

            <Modal
                centered
                width={540}
                footer={null}
                visible={this.state.modalFormVisible}
                onCancel={() => this.setModalFormVisible(false)}
                closeIcon={<Icon name="close" />}
                className="modal-custom">
                {this.state.modalFormType === 'reject' && (
                    <>
                        <h4 className="h-4 text-center">Reject</h4>
                        <div className="form-wrap mt-2">
                            <Form onFinish={this.reject} layout="vertical" ref={this.formRef}>
                                <Form.Item name="reason" label="Reason">
                                    <TextArea />
                                </Form.Item>
                                <Form.Item name="action_required" label="Action required (if applicable)">
                                    <TextArea />
                                </Form.Item>
                                <Form.Item name="action_owner" label="Action owner">
                                    <Select size="large" suffixIcon={<Icon name="chevron-down" />}>
                                        {[
                                            { key: '001', label: 'WSH Location' },
                                            { key: '002', label: 'Supplier' },
                                            { key: '003', label: 'Supply Chain Team' },
                                            { key: '004', label: 'Other' },
                                        ].map(item => (
                                            <Option key={item.key} value={item.label}>
                                                {item.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <div className="mt-4 mb-2 text-center">
                                    <Button type="primary" htmlType="submit" size="large" className="small">
                                        Send
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
                {this.state.modalFormType === 'approve' && (
                    <>
                        <h4 className="h-4 text-center">Approve Supplier</h4>
                        <div className="form-wrap mt-2">
                            <Form
                                onFinish={this.approve}
                                layout="vertical"
                                initialValues={{
                                    price_level: selectedRelationship.price_level?.id,
                                    email: selectedRelationship.supplier.email || '',
                                }}
                                ref={this.formRef}>
                                <div className="mt-4">
                                    <h6 className="h-5">Approved Suppliers</h6>
                                    <div className="mt-2">
                                        {[
                                            { id: '001', title: 'Name', value: [selectedRelationship.supplier.name] },
                                            { id: '002', title: 'ID', value: [selectedRelationship.supplier.creditor_id] },
                                            { id: '003', title: 'Emailed', value: ['26/03/2020', '16:40'] },
                                        ].map(it => (
                                            <div key={it.id} className="list__item">
                                                <h5 className="h-5">{it.title}</h5>
                                                <div className="list__item-val">
                                                    {it.value?.map(p => (
                                                        <span style={{ marginRight: 24 }} key={p}>
                                                            {p}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Form.Item name="price_level" label="Approved Pricing Level" className="mt-3">
                                    <Select size="large" suffixIcon={<Icon name="chevron-down" />}>
                                        {priceLevels.map(item => (
                                            <Option key={item.name} value={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <div className="mt-4">
                                    <UpdateSupplierCategories
                                        supplierCategories={existingSupplierCategories}
                                        allCategories={allCategories}
                                        updateSupplierCategories={this.updateSupplierCategories} />
                                </div>
                                <div className="mt-4">
                                    <Form.Item name="email" label="Supplier Email" className="mb-1">
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className="mt-4">
                                    <Checkbox>Notify supplier of approval?</Checkbox>
                                </div>
                                <Row gutter={24} justify="center" className="mt-5">
                                    <Col>
                                        <Button type="secondary" size="large" className="secondary small" onClick={() => this.setModalFormVisible(false)}>
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button type="primary" htmlType="submit" size="large" className="small">
                                            Approve
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </>
                )}
                {this.state.modalFormType === 'process' && (
                    <>
                        <h4 className="h-4 text-center">Process</h4>
                        <div className="form-wrap mt-2">
                            <Form
                                initialValues={{ price_level: selectedRelationship.price_level?.id }}
                                onFinish={this.process}
                                layout="vertical"
                                ref={this.formRef}>
                                <Form.Item name="price_level" label="Price Level (set by location)">
                                    <Select size="large" suffixIcon={<Icon name="chevron-down" />}>
                                        {priceLevels.map(item => (
                                            <Option key={item.name} value={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Button onClick={() => this.setState({ newPriceLevel: true })} className="underlined">New Price Level</Button>
                                {newPriceLevel ? (
                                    <>
                                        <Row gutter={20} className="mt-2">
                                            <Col span={10}>
                                                <Form.Item label="Name" name="new_price_level_name">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item label="Value" name="new_price_level_value">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <div>
                                            <p className="text-italic fz-12">
                                                New Price Level will be added automatically when approved.
                                            </p>
                                        </div>
                                    </>
                                ) : ''}

                                <div className="mt-3">
                                    <h6 className="h-5">When should this price level start?</h6>
                                    <Row gutter={16} align="middle" className="mt-1">
                                        <Col>
                                            <Radio.Group defaultValue={0} className="checkbox-group-custom">
                                                <Radio value={0}>Immediately</Radio>
                                                <Radio value={1}>Custom date</Radio>
                                            </Radio.Group>
                                        </Col>
                                        <Col>
                                            <DatePicker
                                                style={{ width: 146 }}
                                                placeholder=" /   /"
                                                format="DD/MM/YYYY"
                                                size="large"
                                                suffixIcon={<Icon name="calendar" className="c_blue fz-16" />} />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4">
                                    <h6 className="h-5">Review Supplier & Location Codes</h6>
                                    <div className="mt-3 list--modal">
                                        {supplierAndLocationCodes.map(it => (
                                            <Row key={it.id} className="list__item align-middle" align="middle">
                                                <Col span={6}>
                                                    <h5 className="h-5">{it.title}</h5>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="list__item-val">
                                                        <p>{it.value}</p>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    {it.showState ? (
                                                        <Form.Item name={it.name} style={{ marginBottom: '0px' }}>
                                                            <Input />
                                                        </Form.Item>
                                                    ) : <Button onClick={it.onClick} className="underlined">change</Button>}
                                                </Col>
                                            </Row>
                                        ))}
                                        { showSupplierCodeInput || showLocationCodeInput ? (
                                            <div className="mt-2">
                                                <p className="text-italic fz-12">
                                                    New codes will be updated automatically when approved.
                                                </p>
                                            </div>
                                        ) : ''}
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <p className="text-italic fz-12">
                                        In proceeding the supplier will be automatically emailed to request they submit
                                        further information.
                                    </p>
                                </div>
                                <Row gutter={24} justify="center" className="mt-4">
                                    <Col>
                                        <Button type="secondary" size="large" className="secondary small" onClick={() => this.setModalFormVisible(false)}>
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button type="primary" htmlType="submit" size="large" className="small">
                                            Process
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </>
                )}
                {this.state.modalFormType === 'report' && (
                    <>
                        <h4 className="h-4 text-center">Supplier Report</h4>
                        <div className="form-wrap mt-2">
                            A report has been requested, the supplier should receive an email containing this report shortly.
                        </div>
                    </>
                )}
            </Modal>
        </Layout>
    );
}
