import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    APPROVE_SUPPLY_REQUEST: null,
    APPROVE_SUPPLY_SUCCESS: null,
    APPROVE_SUPPLY_FAILURE: null,

    GET_RELATIONSHIPS_FOR_SCT_REQUEST: null,
    GET_RELATIONSHIPS_FOR_SCT_SUCCESS: null,
    GET_RELATIONSHIPS_FOR_SCT_FAILURE: null,

    SCT_APPROVE_REQUEST: null,
    SCT_APPROVE_SUCCESS: null,
    SCT_APPROVE_FAILURE: null,

    SCT_PROCESS_REQUEST: null,
    SCT_PROCESS_SUCCESS: null,
    SCT_PROCESS_FAILURE: null,

    SCT_REJECT_REQUEST: null,
    SCT_REJECT_SUCCESS: null,
    SCT_REJECT_FAILURE: null,

    TRADING_RELATIONSHIP_REQUEST: null,
    TRADING_RELATIONSHIP_SUCCESS: null,
    TRADING_RELATIONSHIP_FAILURE: null,

    GENERATE_SUPPLIER_REPORT_REQUEST: null,
    GENERATE_SUPPLIER_REPORT_SUCCESS: null,
    GENERATE_SUPPLIER_REPORT_FAILURE: null,
});

export const {
    approveSupplyRequest,
    approveSupplySuccess,
    approveSupplyFailure,
} = createActions(
    constants.APPROVE_SUPPLY_REQUEST,
    constants.APPROVE_SUPPLY_SUCCESS,
    constants.APPROVE_SUPPLY_FAILURE,
);

export const {
    getRelationshipsForSctRequest,
    getRelationshipsForSctSuccess,
    getRelationshipsForSctFailure,
} = createActions(
    constants.GET_RELATIONSHIPS_FOR_SCT_REQUEST,
    constants.GET_RELATIONSHIPS_FOR_SCT_SUCCESS,
    constants.GET_RELATIONSHIPS_FOR_SCT_FAILURE,
);

export const {
    sctApproveRequest,
    sctApproveSuccess,
    sctApproveFailure,
} = createActions(
    constants.SCT_APPROVE_REQUEST,
    constants.SCT_APPROVE_SUCCESS,
    constants.SCT_APPROVE_FAILURE,
);

export const {
    sctProcessRequest,
    sctProcessSuccess,
    sctProcessFailure,
} = createActions(
    constants.SCT_PROCESS_REQUEST,
    constants.SCT_PROCESS_SUCCESS,
    constants.SCT_PROCESS_FAILURE,
);

export const {
    sctRejectRequest,
    sctRejectSuccess,
    sctRejectFailure,
} = createActions(
    constants.SCT_REJECT_REQUEST,
    constants.SCT_REJECT_SUCCESS,
    constants.SCT_REJECT_FAILURE,
);

export const {
    tradingRelationshipRequest,
    tradingRelationshipSuccess,
    tradingRelationshipFailure,
} = createActions(
    constants.TRADING_RELATIONSHIP_REQUEST,
    constants.TRADING_RELATIONSHIP_SUCCESS,
    constants.TRADING_RELATIONSHIP_FAILURE,
);

export const {
    generateSupplierReportRequest,
    generateSupplierReportSuccess,
    generateSupplierReportFailure,
} = createActions(
    constants.GENERATE_SUPPLIER_REPORT_REQUEST,
    constants.GENERATE_SUPPLIER_REPORT_SUCCESS,
    constants.GENERATE_SUPPLIER_REPORT_FAILURE,
);
