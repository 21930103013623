import React from 'react';
import { Input, AutoComplete, Select } from 'antd';
import { TagOutlined, ShopOutlined } from '@ant-design/icons';
import { isNull } from 'underscore';
import approvedAllIcon from '../../assets/images/key-approved-all.svg';
import approvedForCompanyIcon from '../../assets/images/key-approved-for-company.svg';
import indirectIcon from '../../assets/images/key-indirect.svg';
import supplierIcon from '../../assets/images/key-supplier.svg';
import notApprovedIcon from '../../assets/images/exclamation-rounded.svg';
import Icon from '../../elements/Icon';
import './style.scss';

const { Option } = AutoComplete;

export default function () {
    const { categories, suggestions, sidebarVisible, user } = this.props;
    const { subcategories, categorySelected, subcategorySelected } = this.state;

    function getOptions(dataSource) {
        if (dataSource) {
            const options = dataSource.map((opt) => (
                <Option key={opt.name} value={opt.name} className="option">
                    <span className="option__title">{opt.name}</span>
                </Option>
            ));
            return options;
        }
        return [];
    }

    const activeCompany = user?.available_companies?.find((company) => company.id === user?.active_company_id);

    /* eslint-disable react/no-array-index-key */
    const getSupplierSuggestions = (suggestions.suppliers && suggestions.suppliers.map((opt, i) => (
        <Option key={`supplier-${opt}-${i}`} value={opt} className="option">
            <span className="option__icon"><ShopOutlined /></span>
            <span className="option__title">{opt}</span>
        </Option>
    ))) || [];

    const getTagSuggestions = (suggestions.tags && suggestions.tags.map((opt, i) => (
        <Option style={{ backgroundColor: 'lightblue' }} key={`tag-${opt}-${i}`} value={opt} className="option">
            <span className="option__icon"><TagOutlined /></span>
            <span className="option__title">{opt}</span>
        </Option>
    ))) || [];

    const uniqueSearchHistory = suggestions.search_history && [...new Set(suggestions.search_history)];
    const getPrevSearchSuggestions = (suggestions.search_history && uniqueSearchHistory.map((opt, i) => (
        <Option key={`search-${opt}-${i}`} value={opt} className="option">
            <span className="option__icon"><Icon name="search" /></span>
            <span className="option__title">{opt}</span>
        </Option>
    ))) || [];
    /* eslint-enable */

    const getAllSuggestions = [...getPrevSearchSuggestions, ...getSupplierSuggestions, ...getTagSuggestions];

    const SupplierKey = () => {
        const items = [
            { key: 1, label: `Approved for ${activeCompany?.parent?.name}`, icon: approvedAllIcon },
            { key: 2, label: 'Approved for another OpCo', icon: approvedForCompanyIcon },
            { key: 3, label: 'Indirect Supplier', icon: indirectIcon },
            { key: 4, label: 'Supplier Depot', icon: supplierIcon },
            { key: 5, label: 'Not Approved', icon: notApprovedIcon },
        ];
        return (
            <div className="supplier-key-container" style={sidebarVisible ? { top: '100px' } : null}>
                {items.map((item) => (
                    <div className="item-container" key={item.key}>
                        <img src={item.icon} alt="icon" />
                        <span>{item.label}</span>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="top-container">
            <div
                className="searchbar"
                onKeyDownCapture={this.searchKeyDown}>
                <Select
                    className="category-dropdown-search"
                    placeholder="All Categories"
                    size="large"
                    style={{ pointerEvents: 'all', minWidth: '22.5%', marginRight: '5px' }}
                    onChange={this.handleCategoryInput}
                    value={categorySelected}
                    suffixIcon={<Icon name="chevron-down" />}>
                    {getOptions([{ name: 'All Categories' }, ...categories.filter(c => isNull(c.parent_id))])}
                </Select>
                {categorySelected !== undefined && categorySelected !== '' && categorySelected !== 'All Categories' ? (
                    <Select
                        placeholder="All Subcategories"
                        size="large"
                        style={{ pointerEvents: 'all' }}
                        onChange={this.handleSubcategoryInput}
                        value={subcategorySelected}
                        suffixIcon={<Icon name="chevron-down" />}>
                        {getOptions([{ name: 'All Subcategories' }, ...subcategories])}
                    </Select>
                ) : ''}
                <span className="separator-vert" />
                <AutoComplete
                    className="certain-category-search"
                    dropdownClassName="search__dropdown"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 300 }}
                    size="large"
                    style={{ pointerEvents: 'all' }}
                    onChange={this.handleSearchInputChange}
                    dataSource={getAllSuggestions}
                    placeholder="Type here"
                    value={this.state.searchTerm}
                    optionLabelProp="value">
                    <Input.Search style={{ pointerEvents: 'all' }} onSearch={this.handleSearchSubmit} />
                </AutoComplete>
                <span className="separator-vert" />
                <button onClick={this.handleResetSearch} type="button" className="btn transparent" style={{ pointerEvents: 'all' }}>
                    <Icon name="close" />
                </button>
            </div>
            <SupplierKey />
        </div>
    );
}
