// .env constants
const {
    REACT_APP_ENV,

    REACT_APP_TITLE,
    REACT_APP_URL,
    REACT_APP_API_URL,

    REACT_APP_BUGSNAG_KEY,
    REACT_APP_GOOGLE_MAPS_KEY,

    REACT_APP_VINE_DASHBOARD_URL,
    REACT_APP_API_SSO_PATH,

    REACT_APP_GA_TRACKING_ID,
} = process.env;

/**
 * Combines a base URL with a path,
 * clearing out any existing path from
 * the base URL first
 * @param {string} baseUrl The base URL to use
 * @param {string} path The path to use
 */
function urlCombine(baseUrl, path) {
    const urlArray = baseUrl.split('/');
    const protocol = urlArray[0];
    const host = urlArray[2];
    const url = `${protocol}//${host}`;
    return `${url}/${path.replace(/^\/+/g, '')}`;
}

// APP CONSTATNS
const app = {
    ENV: REACT_APP_ENV,
    DEBUG: REACT_APP_ENV === 'local',
    MOCK: false,
    BACKEND: 'laravel', // or 'laravel'

    APP_NAME: REACT_APP_TITLE,

    APP_URL: REACT_APP_URL,
    API_URL: REACT_APP_API_URL,

    BUGSNAG_KEY: REACT_APP_BUGSNAG_KEY,
    GOOGLE_MAPS_KEY: REACT_APP_GOOGLE_MAPS_KEY,

    VINE_DASHBOARD_URL: REACT_APP_VINE_DASHBOARD_URL,
    SSO_LOGIN_URL: REACT_APP_API_SSO_PATH
        ? urlCombine(REACT_APP_API_URL, REACT_APP_API_SSO_PATH)
        : REACT_APP_API_SSO_PATH,

    AUTH_KEY: 'auth-key',
    LOCALSTORAGE_EXPIRES_KEY: 'session-token-expiry',
    LOCALSTORAGE_EXPIRES_TIME: 30, // minutes

    FS14_FORM_LINK: 'https://servicedesk.wshgroup.co.uk/WorkOrder.do?woMode=newWO&from=Templates&module=incident&reqTemplate=17407',
    ADD_SUPPLIER_FORM_LINK: 'https://rosmith.plumsail.io/4f0a9983-9c20-4883-b43c-c0fd1e9c9cdd',
    USE_SUPPLIER_FORM_LINK: 'https://servicedesk.wshgroup.co.uk/WorkOrder.do?woMode=newWO&from=Templates&module=serviceRequest&reqTemplate=15903',
    UNAPPROVED_SUPPLIER_LINK: 'https://servicedesk.wshgroup.co.uk/WorkOrder.do?woMode=newWO&from=Templates&module=serviceRequest&reqTemplate=15903',

    GA_TRACKING_ID: REACT_APP_GA_TRACKING_ID,
};

export default app;
