/* eslint no-multiple-empty-lines: "error" */
import { Record } from 'immutable';

const {
    APPROVE_SUPPLY_REQUEST,
    APPROVE_SUPPLY_SUCCESS,
    APPROVE_SUPPLY_FAILURE,

    GET_RELATIONSHIPS_FOR_SCT_REQUEST,
    GET_RELATIONSHIPS_FOR_SCT_SUCCESS,
    GET_RELATIONSHIPS_FOR_SCT_FAILURE,

    SCT_APPROVE_REQUEST,
    SCT_APPROVE_SUCCESS,
    SCT_APPROVE_FAILURE,

    SCT_PROCESS_REQUEST,
    SCT_PROCESS_SUCCESS,
    SCT_PROCESS_FAILURE,

    SCT_REJECT_REQUEST,
    SCT_REJECT_SUCCESS,
    SCT_REJECT_FAILURE,
    TRADING_RELATIONSHIP_REQUEST,
    TRADING_RELATIONSHIP_SUCCESS,
    TRADING_RELATIONSHIP_FAILURE,

    GENERATE_SUPPLIER_REPORT_REQUEST,
    GENERATE_SUPPLIER_REPORT_SUCCESS,
    GENERATE_SUPPLIER_REPORT_FAILURE,

} = require('./tradingRelationshipActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    modalOpen: false,
    relationshipsForSct: {},
    tradingRelationship: null,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function helpReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.mergeDeep(state);

    switch (type) {

    case APPROVE_SUPPLY_REQUEST:
    case GET_RELATIONSHIPS_FOR_SCT_REQUEST:
    case SCT_APPROVE_REQUEST:
    case SCT_PROCESS_REQUEST:
    case SCT_REJECT_REQUEST:
    case TRADING_RELATIONSHIP_REQUEST:
    case GENERATE_SUPPLIER_REPORT_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case APPROVE_SUPPLY_SUCCESS:
    case SCT_APPROVE_SUCCESS:
    case SCT_PROCESS_SUCCESS:
    case SCT_REJECT_SUCCESS:
    case GENERATE_SUPPLIER_REPORT_SUCCESS:
        return state.set('isFetching', false);
    case TRADING_RELATIONSHIP_SUCCESS:
        return state.set('isFetching', false).set('tradingRelationship', payload.data);

    case GET_RELATIONSHIPS_FOR_SCT_SUCCESS:
        return state.set('isFetching', false).set('relationshipsForSct', payload.data);

    case APPROVE_SUPPLY_FAILURE:
    case GET_RELATIONSHIPS_FOR_SCT_FAILURE:
    case SCT_APPROVE_FAILURE:
    case SCT_PROCESS_FAILURE:
    case SCT_REJECT_FAILURE:
    case TRADING_RELATIONSHIP_FAILURE:
    case GENERATE_SUPPLIER_REPORT_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
