import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'underscore';
import { Spin, Space } from 'antd';
import ReactGA from 'react-ga4';

import history from '../../core/utils/history';

import * as globalActions from '../../core/global/globalActions';
import * as reportActions from '../../core/report/reportActions';
import * as supplierActions from '../../core/supplier/supplierActions';
import * as searchActions from '../../core/search/searchActions';
import * as tradingRelationshipActions from '../../core/tradingRelationship/tradingRelationshipActions';

import DOM from './approveSupply';

class ApproveSupply extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            map: false,
            maps: false,
            page: 1,
            differentPrimaryContact: false,
            canSupply: true,
            copyPrevData: true,
            savedData: {},
        };

        this.DeliveryDays = React.createRef();
    }

    componentDidMount() {
        const { actions, match, requestId } = this.props;
        const { params } = match;

        actions.getPendingRelationshipsRequest({ supplier: +params.id, token: params.token, requestId });

        if (requestId) {
            actions.sortPendingRelationships({ requestId });
        }

        ReactGA.send({ hitType: 'pageview', page: '/approve-supply', title: 'Approve Supply' });
    }

    componentDidUpdate(prevProps, prevState) {
        const { supplierAreas, pendingRelationships } = this.props;
        const { map, maps } = this.state;
        const mapMarkers = [];
        const mapCircles = [];

        if (
            pendingRelationships
            && supplierAreas
            && maps
            && (!isEqual(prevProps.supplierAreas, supplierAreas) || !isEqual(prevState.maps, maps))
        ) {
            supplierAreas.forEach(sa => {
                mapCircles.push(
                    new maps.Circle({
                        strokeColor: '#27c9e5',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#27c9e5',
                        fillOpacity: 0.3,
                        map,
                        center: { lat: parseFloat(sa.latitude), lng: parseFloat(sa.longitude) },
                        radius: parseInt(sa.radius, 10) * 1000,
                    }),
                );
                mapMarkers.push(
                    new maps.Marker({
                        map,
                        position: { lat: parseFloat(sa.latitude), lng: parseFloat(sa.longitude) },
                    }),
                );
            });
        }
    }

    handleApiLoaded = (map, maps) => {
        this.setState({ map, maps });
    };

    nextPage = () => {
        const { pendingRelationships } = this.props;
        const { relationships } = pendingRelationships;
        const { page } = this.state;

        if (page < relationships.length) {
            const newState = this.state;
            newState.page = this.state.page + 1;
            this.setState(newState);
        }

        this.setState({ copyPrevData: true });
    }

    submitForm = vals => {
        const { pendingRelationships, actions } = this.props;
        const { relationships } = pendingRelationships;
        const { page, savedData } = this.state;
        const relationshipId = relationships[page - 1].id;
        const postData = vals;
        const postSavedData = savedData;
        const copyPrevData = page === 1 ? false : this.state.copyPrevData;

        postData.account_activation_date = vals.account_activation_date.format('YYYY-MM-DD');

        if (copyPrevData) {
            postSavedData.can_supply_location = vals.can_supply_location;
            postSavedData.account_number = vals.account_number;
            postSavedData.account_activation_date = vals.account_activation_date;
        } else if (this.DeliveryDays?.current) {
            postData.deliveryDays = JSON.stringify(this.DeliveryDays.current.state.deliveryDays);
        }

        if (page === pendingRelationships.relationships.length) {
            if (copyPrevData) {
                postSavedData.finalPage = true;
            } else {
                postData.finalPage = true;
            }
        }

        actions.approveSupplyRequest({ id: relationshipId, formData: copyPrevData ? postSavedData : postData });

        if (!copyPrevData) {
            this.setState({ savedData: postData });
        }

        if (page < pendingRelationships.relationships.length) {
            this.nextPage();
        } else {
            history.push('/approve-supply-confirmation');
        }
    };

    render() {
        const { pendingRelationships } = this.props;

        if (pendingRelationships) {
            return this.view();
        }

        return (
            <Space size="middle" className="ml-5 mt-5">
                <Spin size="large" />
            </Space>
        );
    }
}

ApproveSupply.defaultProps = {
    pendingRelationships: null,
    requestId: null,
};

ApproveSupply.propTypes = {
    actions: PropTypes.object.isRequired,
    global: PropTypes.object.isRequired,
    supplier: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    pendingRelationships: PropTypes.object,
    supplierAreas: PropTypes.array.isRequired,
    requestId: PropTypes.number,
};

function mapStateToProps(state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.location.search);
    let requestId = null;

    if (urlParams.has('id')) {
        requestId = parseInt(urlParams.get('id'), 10);
    }

    return {
        ...ownProps,
        global: state.global,
        pendingRelationships: state.supplier.pendingRelationships,
        supplier: state.supplier.pendingRelationships ? state.supplier.pendingRelationships.supplier : null,
        supplierAreas: state.supplier.pendingRelationships ? state.supplier.pendingRelationships.supplier_areas : null,
        deliveryDays: state.supplier.pendingRelationships ? state.supplier.pendingRelationships.delivery_days : null,
        requestId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...reportActions,
                ...searchActions,
                ...supplierActions,
                ...tradingRelationshipActions,
            },
            dispatch,
        ),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ApproveSupply);
