import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_AUDIT_LOGS_REQUEST: null,
    GET_AUDIT_LOGS_SUCCESS: null,
    GET_AUDIT_LOGS_FAILURE: null,
});

export const {
    getAuditLogsRequest,
    getAuditLogsSuccess,
    getAuditLogsFailure,
} = createActions(
    constants.GET_AUDIT_LOGS_REQUEST,
    constants.GET_AUDIT_LOGS_SUCCESS,
    constants.GET_AUDIT_LOGS_FAILURE,
);
