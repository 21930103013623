/* eslint no-multiple-empty-lines: "error" */
import { Record } from 'immutable';

const {
    GET_PRICE_LEVELS_REQUEST,
    GET_PRICE_LEVELS_SUCCESS,
    GET_PRICE_LEVELS_FAILURE,
} = require('./priceLevelActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    priceLevels: [],
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function helpReducer(state = initialState, { payload, type }) {
    // eslint-disable-next-line no-param-reassign
    if (!(state instanceof InitialState)) state = initialState.mergeDeep(state);

    switch (type) {

    case GET_PRICE_LEVELS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_PRICE_LEVELS_SUCCESS:
        return state.set('isFetching', false).set('priceLevels', payload.data);

    case GET_PRICE_LEVELS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
