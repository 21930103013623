import { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as categoryActions from '../../core/category/categoryActions';
import * as searchActions from '../../core/search/searchActions';
import * as userActions from '../../core/user/userActions';

import DOM from './searchBar';

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categorySelected: undefined,
            subcategories: [],
            subcategorySelected: undefined,
            searchTerm: '',
            chosenCategory: null,
            searchBarVisible: false,
            hasSearched: false,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getCategoriesRequest();
        this.updateSearchBarValues();
    }

    componentDidUpdate(prevProps) {
        const { searchParams: { category, name } } = this.props;
        const { searchParams: { category: prevCategory, name: prevName } } = prevProps;

        if (category !== prevCategory || name !== prevName) {
            this.updateSearchBarValues();
        }
    }

    updateSearchBarValues = () => {
        const { searchParams, categories } = this.props;
        const { category, name } = searchParams;

        const categoryObj = categories.find(c => c.id === category) || { name: undefined };
        let parentCategoryObj = null;
        if (categoryObj.parent_id) {
            parentCategoryObj = categories.find(c => c.id === categoryObj.parent_id);
        }

        // Set stored search params
        this.setState({
            searchTerm: name || '',
            categorySelected: parentCategoryObj ? parentCategoryObj.name : categoryObj.name,
            subcategorySelected: parentCategoryObj ? categoryObj.name : undefined,
        });
    }

    handleCategoryChange = value => {
        this.setState({ chosenCategory: value });
    };

    hideSearchForm = () => {
        this.setState({ searchBarVisible: false });
    }

    handleCategoryInput = (value) => {
        const { categories } = this.props;

        const category = categories.find(cat => cat.name === value);
        if (category) {
            const subcats = categories.filter(cat => cat.parent_id === category.id);
            this.setState({
                categorySelected: value,
                subcategories: subcats,
                subcategorySelected: null,
            });
        } else {
            this.setState({ categorySelected: value });
        }
    }

    handleSubcategoryInput = (value) => {
        this.setState({ subcategorySelected: value });
    }

    handleSearchInputChange = (value) => {
        const { actions, categories } = this.props;
        const { categorySelected, subcategorySelected } = this.state;

        let category = null;
        let subcategory = null;
        if (categorySelected) {
            category = categories.find(c => c.name === this.state.categorySelected);
        }
        if (subcategorySelected) {
            subcategory = categories.find(c => c.name === this.state.subcategorySelected);
        }

        const categoryId = category ? category.id : null;
        const subcategoryId = subcategory ? subcategory.id : null;

        this.setState({ searchTerm: value });
        if (value !== undefined && value.length > 2) {
            actions.getSuggestionsRequest({
                searchTerm: value,
                categoryId: subcategoryId || categoryId,
            });
        }
    }

    handleResetSearch = () => {
        const { actions, companyId, userLocation } = this.props;

        const { lat, lng } = userLocation;
        actions.clearSearch();
        actions.getResultsRequest({
            start: 0,
            finish: 10,
            companyId,
            latitude: lat,
            longitude: lng,
            radius: 6371,
        });
        actions.getPinsRequest({
            companyId,
            latitude: lat,
            longitude: lng,
            radius: 6371,
        });
        actions.hideResultsSidebar();

        this.setState({ searchTerm: '', categorySelected: undefined, subcategorySelected: undefined });
    }

    search = (maincategory = null, subscategory = null) => {
        const { actions, categories, companyId, userLocation, isMobile, activateFindNearestPin } = this.props;

        const categorySelected = maincategory === null ? this.state.categorySelected : maincategory;
        const subcategorySelected = subscategory === null ? this.state.subcategorySelected : subscategory;

        const cat = categories.find(category => category.name === categorySelected);
        const catId = cat !== undefined ? cat.id : '';
        const subcat = categories.find(category => category.name === subcategorySelected);
        const subcatId = subcat !== undefined ? subcat.id : '';

        const term = this.state.searchTerm;

        const getCatName = () => {
            if (subcat !== undefined) {
                return subcat.name;
            }
            if (cat !== undefined) {
                return cat.name;
            }
            return '';
        };

        const { lat, lng } = userLocation;

        const data = {
            category: subcatId !== '' ? subcatId : catId,
            catName: getCatName(),
            catId: subcatId || catId,
            name: term,
            start: 0,
            finish: 10,
            companyId,
            latitude: lat,
            longitude: lng,
            radius: 6371,

        };

        const pinData = {
            category: subcatId !== '' ? subcatId : catId,
            name: term,
            companyId,
            latitude: lat,
            longitude: lng,
            radius: 6371,
        };

        actions.clearSearch();
        actions.hideSupplierDetails();
        if (!isMobile) {
            actions.showResultsSidebar();
        }
        actions.getResultsRequest(data);
        actions.getPinsRequest(pinData);
        activateFindNearestPin();
    };

    handleSearchSubmit = () => {
        this.setState({ hasSearched: true });
        this.search();
    }

    searchKeyDown = (event) => {
        let code = '';
        if (event.key !== undefined) {
            code = event.key;
        } else if (event.keyIdentifier !== undefined) {
            code = event.keyIdentifier;
        } else if (event.keyCode !== undefined) {
            code = event.keyCode;
        }

        if (code === 'Enter') {
            event.preventDefault();
            event.stopPropagation();

            this.search();
        }
    }

    render() {
        return this.view(this.props);
    }
}

SearchBar.propTypes = {
    actions: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    companyId: PropTypes.number.isRequired,
    userLocation: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    searchParams: PropTypes.object.isRequired,
    activateFindNearestPin: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        categories: state.category.categories,
        suggestions: state.search.suggestions,
        companyId: state.user.userData.active_company_id,
        userLocation: state.user.mapLocation && state.user.mapLocation.coordinates,
        searchParams: state.search.searchParams,
        user: state.user.userData,
        userCompanies: state.user.userData && state.user.userData.available_companies ? state.user.userData.available_companies : [],
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...categoryActions,
                ...searchActions,
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
