import React from 'react';
import { Row, Col, Button, Select, Popover } from 'antd';
import { find } from 'underscore';

import Icon from '../../elements/Icon';
import './style.scss';
import SearchResult from '../SearchResult';

const renderApprovalPopover = (result, user) => {
    const { userData } = user;
    let companyName = null;
    const company = find(userData.available_companies, c => c.id === userData.active_company_id);
    if (company) {
        companyName = company.parent ? company.parent.name : company.name;
    }

    if (result.tier) {
        if (result.tier === '1' || result.tier === '2') {
            if (result.approved) {
                return (
                    <Popover placement="left"
                        content={(
                            <div className="popover__custom c_gold">
                                <div className="popover__header">
                                    <Icon name="star" />
                                    <h4 className="gold-supplier-heading h-4">Approved for {companyName}</h4>
                                </div>
                                <p>This supplier has been approved for immediate use within {companyName} by WSH Procurement.</p>
                            </div>
                        )}
                        trigger="hover">
                        {' '}
                        <Icon name="star" />
                    </Popover>
                );
            }
            return (
                <Popover placement="left"
                    content={(
                        <div className="popover__custom c_green">
                            <div className="popover__header">
                                <Icon name="checkmark" />
                                <h4 className="h-4">Approved for another OpCo</h4>
                            </div>
                            <p>This supplier has been approved for use within another OpCo by WSH Procurement.</p>
                        </div>
                    )}
                    trigger="hover">
                    {' '}
                    <Icon name="checkmark" className="c_green" />
                </Popover>
            );

        } if (result.tier === '2.1') {
            return (
                <Popover placement="left"
                    content={(
                        <div className="popover__custom">
                            <div className="popover__header">
                                <Icon name="not-approved" />
                                <h4 className="h-4">Not Approved</h4>
                            </div>
                            <p>This supplier is not approved and will need approval from WSH Procurement before it can be used.</p>
                        </div>
                    )}
                    trigger="hover">
                    {' '}
                    <Icon name="not-approved" />
                </Popover>
            );
        }
    }

    if (result.is_global) {
        return (
            <Popover placement="left"
                content={(
                    <div className="popover__custom c_gold">
                        <div className="popover__header">
                            <Icon name="star" />
                            <h4 className="gold-supplier-heading h-4">Approved for {companyName}</h4>
                        </div>
                        <p>This supplier has been approved for immediate use within {companyName} by WSH Procurement.</p>
                    </div>
                )}
                trigger="hover">
                {' '}
                <Icon name="star" />
            </Popover>
        );
    }

    if (result.parent_id) {
        return (
            <Popover placement="left"
                content={(
                    <div className="popover__custom">
                        <div className="popover__header">
                            <Icon name="truck" style={{ fontSize: '20px' }} />
                            <h4 className="h-4">Supplier Depot</h4>
                        </div>
                        <p>This represents a supplier&apos;s delivery depot</p>
                    </div>
                )}
                trigger="hover">
                {' '}
                <Icon name="truck" style={{ fontSize: '20px' }} />
            </Popover>
        );
    }

    if (result.approved) {
        return (
            <Popover placement="left"
                content={(
                    <div className="popover__custom c_green">
                        <div className="popover__header">
                            <Icon name="checkmark" />
                            <h4 className="h-4">Approved for another OpCo</h4>
                        </div>
                        <p>This supplier has been approved for use within another OpCo by WSH Procurement.</p>
                    </div>
                )}
                trigger="hover">
                {' '}
                <Icon name="checkmark" className="c_green" />
            </Popover>

        );
    }
    if (!result.direct) {
        return (
            <Popover placement="left"
                content={(
                    <div className="popover__custom c_red">
                        <div className="popover__header">
                            <Icon name="indirect" />
                            <h4 className="h-4">Indirect Supplier</h4>
                        </div>
                        <p>Our Indirect Suppliers are our growers / manufacturers who provide products into our nominated suppliers</p>
                    </div>
                )}
                trigger="hover">
                {' '}
                <Icon name="indirect" className="c_red" />
            </Popover>
        );
    }
    return (
        <Popover placement="left"
            content={(
                <div className="popover__custom">
                    <div className="popover__header">
                        <Icon name="not-approved" />
                        <h4 className="h-4">Not Approved</h4>
                    </div>
                    <p>This supplier is not approved and will need approval from WSH Procurement before it can be used.</p>
                </div>
            )}
            trigger="hover">
            {' '}
            <Icon name="not-approved" />
        </Popover>
    );
};

export default function () {
    const { results, resultCount, user, groupBy } = this.props;
    return (
        <div className="sidebar-results" onScroll={this.handleResultsScroll}>
            <div className="results__header">
                <span className="result__count">
                    <h3 className="h-3">{resultCount} Results</h3>
                    <Button className="close-sidebar-results" onClick={this.handleSidebarClose}>
                        <Icon name="map" />
                        <span style={{ marginLeft: 10 }}>Map</span>
                    </Button>
                </span>
                <Row type="flex" align="middle" gutter={24} className="mt-2">
                    <Col span={12}>
                        <div className="select-label">Sort by</div>
                        <Select
                            onChange={this.handleSortResults}
                            size="large"
                            defaultValue="recommended"
                            className="w100"
                            suffixIcon={<Icon name="chevron-down" />}>
                            <Select.Option value="proximity">Proximity</Select.Option>
                            <Select.Option value="recommended">Recommended</Select.Option>
                            <Select.Option value="supplier">Supplier</Select.Option>
                        </Select>
                    </Col>
                    <Col span={12}>
                        {user.userData.role.name === 'Supply Chain Manager' ? (
                            <>
                                <div className="select-label">Pin grouping</div>
                                <Select
                                    defaultValue={null}
                                    value={groupBy}
                                    size="large"
                                    className="w100"
                                    suffixIcon={<Icon name="chevron-down" />}
                                    onChange={this.handlePinGroupChange}>
                                    <Select.Option value={null}>-</Select.Option>
                                    <Select.Option value="recommended">By Recommended</Select.Option>
                                    <Select.Option value="proximity">By Proximity</Select.Option>
                                    <Select.Option value="supplier">By Supplier</Select.Option>
                                </Select>
                            </>
                        ) : false}
                    </Col>
                </Row>
            </div>
            <div className="results__body">

                {results.map((result, key) => (
                    <SearchResult
                        key={result.id}
                        result={result}
                        theKey={key}
                        renderApprovalPopover={renderApprovalPopover}
                        renderInfoDescription={this.renderInfoDescription}
                        getSupplierDetails={this.props.getSupplierDetails}
                        user={user} />
                ))}
            </div>
        </div>
    );
}
