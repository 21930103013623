import React from 'react';
import { Form, Button, Input, Tag, Checkbox, Slider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import { isEmpty } from 'underscore';

import Layout from '../../components/layout/Layout';
import Icon from '../../elements/Icon';
import WhsLogo from '../../assets/images/wsh-logo.svg';
import DeliveryDays from '../../components/DeliveryDays';
import UpdateSupplierCategories from '../../components/UpdateSupplierCategories';
import './style.scss';

const { GOOGLE_MAPS_KEY } = require('../../core/constants').default;
const { MAP_STYLES } = require('../../core/utils/values').default;

const { TextArea } = Input;

export default function () {
    const { match, supplier, deliveryDays, allCategories, supplierCategories } = this.props;
    const { selectedMarker, mapSliderValue, tagInputVisible, agreeInfoCorrect, agreeToShareData } = this.state;
    const { address, manager } = supplier;
    const backLink = {
        link: `/approve-supply/${match.params.id}/${match.params.token}`,
        title: 'Update your Trading Company information',
    };

    const infoList = [
        {
            id: '001',
            title: 'Account number',
            descr: supplier.id,
        },
        {
            id: '002',
            title: 'Creditor ID',
            descr: supplier.creditor,
        },
        {
            id: '003',
            title: 'Trading company',
            descr: supplier.name,
        },
        {
            id: '004',
            title: 'First added',
            descr: moment(supplier.added_on).format('DD-MM-Y'),
        },
    ];

    return (
        <Layout
            title={<div>Welcome</div>}
            backLink={backLink}
            headerTitle={(
                <div>
                    <Icon name="arrow-left" />
                    <img src={WhsLogo} alt="logo" />
                </div>
            )}>
            <div className="content-wrap">
                <div className="content-contr">
                    <Form
                        initialValues={{
                            description: supplier.description,
                            address_line_1: address.address_1,
                            address_line_2: address.address_2,
                            city: address.town,
                            county: address.county,
                            postcode: address.postcode,
                            country: address.country,
                            email: supplier.email,
                            website: supplier.website,
                            social_media_link: supplier.social_media_link,
                            account_manager_name: manager.name,
                            account_manager_email: manager.email,
                            account_manager_phone: manager.phone_number,
                        }}
                        layout="vertical"
                        name="trading-company-info"
                        onFinish={this.submitForm}>
                        <div className="content__block mt-5">
                            <div className="block__header">
                                <h4 className="h-4">Company information</h4>
                                <p className="block__header-subtitle">
                                    (company information cannot be changed via this form, if you’d like to change your
                                    company name or account number, then please{' '}
                                    <span onClick={this.props.actions.openHelpModal} className="anchor-color-blue underlined">
                                        contact us
                                    </span>
                                    )
                                </p>
                            </div>
                            <div>
                                {infoList.map(it => (
                                    <div key={it.id} className="list__item">
                                        <h5 className="h-5">{it.title}</h5>
                                        <p className="list__item-value">{it.descr}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Trading Company Primary Address</h4>
                            </div>
                            <div className="mt-3">
                                <Form.Item
                                    label="Address line 1"
                                    name="address_line_1"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="Address line 2"
                                    name="address_line_2"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="Town / City"
                                    name="city"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="County"
                                    name="county"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="Postcode / zip code"
                                    name="postcode"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="Country"
                                    name="country"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="Key Contact Email Address"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input valid value',
                                        },
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Your Business Categories</h4>
                                <p className="block__header-subtitle">
                                    We use this information to help WSH companies find and use you, so please make sure
                                    your categories are correct and up to date if you would like to appear on searches.
                                </p>
                            </div>
                            <div className="mt-3 mb-4">
                                <UpdateSupplierCategories
                                    supplierCategories={supplierCategories}
                                    allCategories={allCategories}
                                    updateSupplierCategories={this.updateSupplierCategories} />
                            </div>
                        </div>
                        <div className="content__block mt-5">
                            <div className="block__header">
                                <h4 className="h-4">Description & Keywords</h4>
                            </div>
                            <div className="mt-4">
                                <h4 className="h-4">Keywords that describe what you offer</h4>
                                <p className="h-6 text-italic fw-400">Up to 25 keywords, comma separated</p>
                                <div className="tags-box">
                                    {this.state.tags.map((tag, key) => (
                                        <Tag onClose={() => this.removeTag(key)} key={tag} closable closeIcon={<Icon name="close" />}>
                                            {tag}
                                        </Tag>
                                    ))}

                                    {tagInputVisible ? (
                                        <Input
                                            type="text"
                                            size="large"
                                            className="tag-input"
                                            style={{ width: '100px' }}
                                            onChange={this.handleTagInput}
                                            onPressEnter={this.handleTagSubmit} />
                                    ) : (
                                        <Tag className="site-tag-plus" onClick={this.showTagInput}>
                                            <PlusOutlined /> New Tag
                                        </Tag>
                                    )}
                                </div>
                            </div>
                            <div className="mt-4">
                                <h4 className="h-4">Description of your Trading Company and what you do</h4>
                                <p className="h-6 text-italic fw-400">
                                    Please consider this carefully, as wsh companies may choose between you and another
                                    supplier based on what you say here.
                                </p>
                                <div className="textarea-box">
                                    <Form.Item
                                        name="description"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input valid value',
                                            },
                                        ]}>
                                        <TextArea initialValue={supplier.description} />
                                    </Form.Item>
                                    <div className="textarea-limit">0/250</div>
                                </div>
                            </div>
                        </div>
                        <DeliveryDays supplierDeliveryDays={deliveryDays} ref={this.DeliveryDays} />
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Primary Account Manager</h4>
                                <p className="block__header-subtitle">
                                    This should be the main account manager for your Trading Company, not for locations
                                    or service areas within your trading company
                                </p>
                            </div>
                            <div className="mt-3">
                                <Form.Item label="Name" name="account_manager_name">
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item label="Email" name="account_manager_email">
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item label="Phone" name="account_manager_phone">
                                    <Input size="large" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Links</h4>
                            </div>
                            <div className="mt-3">
                                <Form.Item label="Website" name="website">
                                    <Input size="large" />
                                </Form.Item>
                                <Form.Item label="Social Media" name="social_media_link">
                                    <Input size="large" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="content__block">
                            <div className="block__header">
                                <h4 className="h-4">Your Supply Area</h4>
                                <Button className="btn underlined" style={{ marginTop: 4 }}>
                                    Cancel
                                </Button>

                                <p className="block__header-subtitle">
                                    You won’t show up to wsh companies outside of your supply area. <br /> This is the
                                    supply radius for your entire trading company, not just your business unit.
                                </p>
                            </div>
                            <div className="mt-3">
                                {supplier.latitude && supplier.longitude ? (
                                    <>
                                        <p className="text-center">Click on the map to add a new pin</p>
                                        <div className="map">
                                            <GoogleMapReact
                                                options={{
                                                    styles: MAP_STYLES,
                                                }}
                                                bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
                                                defaultCenter={{ lat: parseFloat(supplier.latitude), lng: parseFloat(supplier.longitude) }}
                                                defaultZoom={10}
                                                yesIWantToUseGoogleMapApiInternals
                                                onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)} />
                                            <div className="map__point-control">
                                                <Slider onChange={!isEmpty(selectedMarker) ? this.setMarkerRadius : () => {}} value={mapSliderValue} className="slider-custom" />
                                                <Input value={mapSliderValue} size="small" className="ml-4" />
                                                <span className="ml-1">km ({(mapSliderValue * 0.62137119).toFixed(2)} mi)</span>
                                                <span className="separator-vert " />
                                                <Button onClick={this.removeMarker} className="btn underlined">Remove</Button>
                                            </div>
                                        </div>
                                    </>
                                ) : <p style={{ textAlign: 'center' }}>**This section is currently unavailable, as a supplier location change is currently processing, please come back later**</p>}
                            </div>
                        </div>

                        <div className="form__footer-confirm">
                            <div className="mt-2">
                                <Checkbox onChange={() => this.setState({ agreeInfoCorrect: !agreeInfoCorrect })}>
                                    I confirm the above information is correct, and applies to the entire Trading
                                    Company.
                                </Checkbox>
                            </div>
                            <div className="mt-2">
                                <Checkbox onChange={() => this.setState({ agreeToShareData: !agreeToShareData })}>
                                    I confirm I have acquired appropriate permission to share the above information with
                                    wsh suppliers and third parties.
                                </Checkbox>
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                            <Button type="primary" htmlType="submit" className="primary wide" disabled={(agreeInfoCorrect || !agreeToShareData)}>
                                Save and Return
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Layout>
    );
}
