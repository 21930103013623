import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Row, Col, Button } from 'antd';
import Icon from '../../elements/Icon';
import './style.scss';

const { Option } = Select;

class SupplierCategoryRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: this.props.currentCategory.id,
            selectedSubcategory: this.props.currentCategory.subcatId,
        };
    }

    changeCategory = (e) => {
        const { updateCategory, index } = this.props;

        this.setState({ selectedCategory: e, selectedSubcategory: null });
        updateCategory(index, e, null);
    }

    changeSubcategory = (e) => {
        const { updateCategory, index } = this.props;
        const { selectedCategory } = this.state;

        this.setState({ selectedSubcategory: e });
        updateCategory(index, selectedCategory, e);
    }

    render() {
        const { allCategories, allSubcategories, removeCategory, index } = this.props;
        const { selectedCategory, selectedSubcategory } = this.state;

        return (
            <Row span={24} className="mb-3">
                <Col span={24}>
                    {index === 0
                        ? (
                            <Row>
                                <p className="primary-category-heading">Primary Category</p>
                            </Row>
                        ) : ''}

                    <Row span={24} gutter={2}>
                        <Col span={10}>
                            <Select
                                value={selectedCategory || 'Select a category'}
                                onChange={this.changeCategory}
                                className={index === 0 ? 'primary-category-select' : ''}
                                suffixIcon={<Icon name="chevron-down" />}
                                size="large"
                                style={{ width: '100%' }}>
                                {allCategories.map(cat => (
                                    <Option key={cat.id} value={cat.id}>{cat.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={10}>
                            <Select
                                value={selectedSubcategory || 'All subcategories'}
                                onChange={this.changeSubcategory}
                                className={index === 0 ? 'primary-category-select' : ''}
                                suffixIcon={<Icon name="chevron-down" />}
                                size="large"
                                style={{ width: '100%' }}>
                                {allSubcategories.filter(scat => scat.parent_id === selectedCategory).map(scat => (
                                    <Option key={scat.id} value={scat.id}>{scat.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={2}>
                            {index !== 0 ? (
                                <Button onClick={() => removeCategory(index)} className="btn underlined ml-2 mt-1">Remove</Button>
                            ) : ''}

                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

SupplierCategoryRow.propTypes = {
    allCategories: PropTypes.array.isRequired,
    allSubcategories: PropTypes.array.isRequired,
    updateCategory: PropTypes.func.isRequired,
    removeCategory: PropTypes.func.isRequired,
    currentCategory: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
};

export default SupplierCategoryRow;
