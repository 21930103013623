import React from 'react';
import { Row, Col, Checkbox, TimePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

class DeliveryDays extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryDays: [],
            defaultDeliveryDays: [
                {
                    day: 'Monday',
                    active: false,
                    allDay: false,
                    start: '09:00',
                    end: '17:00',
                },
                {
                    day: 'Tuesday',
                    active: false,
                    allDay: false,
                    start: '09:00',
                    end: '17:00',
                },
                {
                    day: 'Wednesday',
                    active: false,
                    allDay: false,
                    start: '09:00',
                    end: '17:00',
                },
                {
                    day: 'Thursday',
                    active: false,
                    allDay: false,
                    start: '09:00',
                    end: '17:00',
                },
                {
                    day: 'Friday',
                    active: false,
                    allDay: false,
                    start: '09:00',
                    end: '17:00',
                },
                {
                    day: 'Saturday',
                    active: false,
                    allDay: false,
                    start: '09:00',
                    end: '17:00',
                },
                {
                    day: 'Sunday',
                    active: false,
                    allDay: false,
                    start: '09:00',
                    end: '17:00',
                },

            ],
        };
    }

    componentDidMount() {
        const { defaultDeliveryDays } = this.state;
        const { supplierDeliveryDays } = this.props;
        const updatedDeliveryDays = [];
        if (supplierDeliveryDays && supplierDeliveryDays !== []) {
            defaultDeliveryDays.forEach(day => {
                let dayExists = false;
                supplierDeliveryDays.forEach(sday => {
                    if (sday.day === day.day.toLowerCase()) {
                        dayExists = true;
                        updatedDeliveryDays.push({
                            day: day.day,
                            active: true,
                            allDay: sday.all_day,
                            start: sday.start,
                            end: sday.end,
                        });
                    }
                });
                if (!dayExists) {
                    updatedDeliveryDays.push(day);
                }
            });
            this.setState({ deliveryDays: updatedDeliveryDays });
        }
    }

    toggleAllDay = (selectedDay) => {
        const { deliveryDays } = this.state;
        const stateDay = deliveryDays.findIndex(day => day.day === selectedDay);

        const newState = deliveryDays;
        if (!deliveryDays[stateDay].allDay) {
            newState[stateDay].allDay = true;
        } else {
            newState[stateDay].allDay = false;
        }

        newState[stateDay].start = null;
        newState[stateDay].end = null;

        this.setState({ deliveryDays: newState });
    }

    toggleDayActive = (selectedDay) => {
        const { deliveryDays } = this.state;
        const stateDay = deliveryDays.findIndex(day => day.day === selectedDay);
        const newState = deliveryDays;

        if (!deliveryDays[stateDay].active) {
            newState[stateDay].active = true;
        } else {
            newState[stateDay].active = false;
        }

    }

    changeTimes = (e, selectedDay, startOrEnd) => {
        const { deliveryDays } = this.state;
        const stateDay = deliveryDays.findIndex(day => day.day === selectedDay);
        const newState = deliveryDays;

        if (e) {
            const time = e.format('HH:mm:ss');
            if (startOrEnd === 'start') {
                newState[stateDay].start = time;
            } else {
                newState[stateDay].end = time;
            }
        } else if (startOrEnd === 'start') {
            newState[stateDay].start = false;
        } else {
            newState[stateDay].end = false;
        }
        this.setState({ deliveryDays: newState });
    }

    render() {
        const { deliveryDays } = this.state;
        const timeFormat = 'HH:mm';
        return (
            <div className="content__block mt-5">
                <div className="block__header">
                    <h4 className="h-4">Delivery Schedule</h4>
                    <p className="block__header-subtitle">
                        Delivery specifics require to be sent directly to the location <br />
                        This will set your standard delivery schedule
                    </p>
                </div>
                <div className="mt-3">
                    <div className="delivery-schedule">
                        {deliveryDays.map(day => (
                            <div key={day.day}>
                                <Row gutter={24}>
                                    <Col>
                                        <Row className="delivery-schedule__checks">
                                            <Col span={12}>
                                                <Checkbox onChange={() => this.toggleDayActive(day.day)} defaultChecked={day.active}>{day.day}</Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox onChange={() => this.toggleAllDay(day.day)} defaultChecked={day.allDay}>All day</Checkbox>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        {!day.allDay && (
                                            <div>
                                                <TimePicker
                                                    onChange={(e) => this.changeTimes(e, day.day, 'start')}
                                                    defaultValue={moment(day.start || '09:00', timeFormat)}
                                                    format={timeFormat}
                                                    size="large" />
                                                <span className="inter-divider">to</span>
                                                <TimePicker
                                                    onChange={(e) => this.changeTimes(e, day.day, 'end')}
                                                    defaultValue={moment(day.end || '17:00', timeFormat)}
                                                    format={timeFormat}
                                                    size="large" />
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

DeliveryDays.propTypes = {
    // eslint-disable-next-line react/require-default-props
    supplierDeliveryDays: PropTypes.oneOfType([
        PropTypes.object.isRequired,
        PropTypes.array.isRequired,
    ]),

};

export default DeliveryDays;
