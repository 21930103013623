import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_PRICE_LEVELS_REQUEST: null,
    GET_PRICE_LEVELS_SUCCESS: null,
    GET_PRICE_LEVELS_FAILURE: null,
});

export const {
    getPriceLevelsRequest,
    getPriceLevelsSuccess,
    getPriceLevelsFailure,
} = createActions(
    constants.GET_PRICE_LEVELS_REQUEST,
    constants.GET_PRICE_LEVELS_SUCCESS,
    constants.GET_PRICE_LEVELS_FAILURE,
);
