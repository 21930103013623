import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Button } from 'antd';
import SupplierCategoryRow from '../SupplierCategoryRow';

class UpdateSupplierCategories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            supplierCategories: [],
        };
    }

    componentDidMount() {
        const { supplierCategories, updateSupplierCategories } = this.props;
        const supplierParentCategories = supplierCategories.filter(scat => !scat.parent_id);
        const supplierSubcategories = supplierCategories.filter(scat => scat.parent_id);
        const mergedSupplierCategories = [];
        let hasPrimary = false;

        supplierParentCategories.forEach(pcat => {
            const subcatId = supplierSubcategories.find(scat => scat.parent_id === pcat.id)?.id || null;
            mergedSupplierCategories.push({
                id: pcat.id,
                subcatId,
                isPrimary: pcat.pivot.is_primary,
            });
            if (pcat.pivot.is_primary) {
                hasPrimary = true;
            }
        });

        if (!hasPrimary) {
            mergedSupplierCategories[0].isPrimary = true;
        }

        updateSupplierCategories(mergedSupplierCategories);

        this.setState({ supplierCategories: mergedSupplierCategories.sort((a, b) => b.isPrimary - a.isPrimary) });
    }

    addNewCategory = () => {
        const { supplierCategories } = this.state;

        supplierCategories.push({});
        this.setState({ supplierCategories });
    };

    removeCategory = (index) => {
        const { supplierCategories } = this.state;
        const { updateSupplierCategories } = this.props;

        supplierCategories.splice(index, 1);
        this.setState({ supplierCategories });
        updateSupplierCategories(supplierCategories);
    };

    updateCategory = (index, catId, subcatId) => {
        const { supplierCategories } = this.state;
        const { updateSupplierCategories } = this.props;

        supplierCategories[index].id = catId;
        supplierCategories[index].subcatId = subcatId;
        this.setState({ supplierCategories });
        updateSupplierCategories(supplierCategories);
    };

    render() {
        const { supplierCategories } = this.state;
        const { allCategories } = this.props;
        const allParentCategories = allCategories.filter(cat => !cat.parent_id);
        const allSubcategories = allCategories.filter(cat => cat.parent_id);

        return (
            <Col>
                <div className="ant-col ant-form-item-label mb-2">
                    <label>Supplier Categories</label>
                </div>
                {supplierCategories.map((sc, index) => (
                    <SupplierCategoryRow
                        key={sc.id}
                        index={index}
                        allCategories={allParentCategories}
                        allSubcategories={allSubcategories}
                        currentCategory={sc}
                        removeCategory={this.removeCategory}
                        updateCategory={this.updateCategory} />
                ))}

                <Button onClick={this.addNewCategory} type="secondary" size="large" className="secondary middle">
                    Add Category
                </Button>
            </Col>
        );
    }

}

UpdateSupplierCategories.propTypes = {
    supplierCategories: PropTypes.array.isRequired,
    allCategories: PropTypes.array.isRequired,
    updateSupplierCategories: PropTypes.func.isRequired,
};

export default UpdateSupplierCategories;
