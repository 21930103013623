import { Record } from 'immutable';

const {
    UPDATE_SESSION_REQUEST,
    UPDATE_SESSION_SUCCESS,
    UPDATE_SESSION_FAILURE,
} = require('./userSessionActions').constants;

const InitialState = Record({
    isFetching: false,
    error: null,
});

const initialState = new InitialState();

/**
 * ## globalReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userSessionReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case UPDATE_SESSION_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case UPDATE_SESSION_SUCCESS:
        return state.set('isFetching', false);

    case UPDATE_SESSION_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
