import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'underscore';
import ReactGA from 'react-ga4';

import * as requestsActions from '../../core/requests/requestsActions';
import * as globalActions from '../../core/global/globalActions';
import * as helpActions from '../../core/help/helpActions';
import DOM from './supplierViewRequest';
import * as tradingRelationshipActions from '../../core/tradingRelationship/tradingRelationshipActions';

class SupplierViewRequest extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            activeTabKey: 'location_information',
        };
    }

    // mount the component
    componentDidMount() {
        const { actions, match } = this.props;
        const relationshipId = match.params.id;
        actions.tradingRelationshipRequest(relationshipId);
        ReactGA.send({ hitType: 'pageview', page: '/supplier-view-request', title: 'Supplier View Request' });
    }

    componentDidUpdate(prevProps, prevState) {
        const { supplierAreas } = this.props;
        const { map, maps } = this.state;
        const mapMarkers = [];
        const mapCircles = [];

        if (
            supplierAreas
            && maps
            && (!isEqual(prevProps.supplierAreas, supplierAreas) || !isEqual(prevState.maps, maps))
        ) {
            supplierAreas.forEach(sa => {
                mapCircles.push(
                    new maps.Circle({
                        strokeColor: '#27c9e5',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#27c9e5',
                        fillOpacity: 0.3,
                        map,
                        center: { lat: parseFloat(sa.latitude), lng: parseFloat(sa.longitude) },
                        radius: parseInt(sa.radius, 10) * 1000,
                    }),
                );
                mapMarkers.push(
                    new maps.Marker({
                        map,
                        position: { lat: parseFloat(sa.latitude), lng: parseFloat(sa.longitude) },
                    }),
                );
            });
        }
    }

    onTabsChange = status => {
        // eslint-disable-next-line no-console
        console.log(status);
    };

    handleApiLoaded = (map, maps) => {
        this.setState({ map, maps });
    };

    render() {
        return this.view();
    }
}

SupplierViewRequest.defaultProps = {
    currentCompany: 0,
    supplierAreas: [],
    tradingRelationship: '',
};

SupplierViewRequest.propTypes = {
    actions: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    requests: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    currentCompany: PropTypes.number,
    tradingRelationship: PropTypes.object,
    supplierAreas: PropTypes.array,
    match: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        page: state.requests.page,
        requests: state.requests.items,
        total: state.requests.total,
        loading: state.requests.isFetching,
        user: state.user,
        currentCompany: state.user.userData.active_company_id,
        tradingRelationship: state.tradingRelationship.tradingRelationship,
        supplierAreas: state.tradingRelationship.tradingRelationship?.supplier.supplierAreas,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...requestsActions,
                ...globalActions,
                ...helpActions,
                ...tradingRelationshipActions,
            },
            dispatch,
        ),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SupplierViewRequest);
