import ReactGA4 from 'react-ga4';
import app from '../core/constants';

const { GA_TRACKING_ID } = app;

const InitializeGoogleAnalytics = () => {
    // Initialize GA4 - Add your measurement ID
    ReactGA4.initialize(GA_TRACKING_ID);
};

const TrackGoogleAnalyticsEvent = (
    eventName,
    data,
) => {

    const eventParams = {
        ...data,
    };

    ReactGA4.event(eventName, eventParams);
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };
