import React from 'react';
import { Row, Col, Form, Button, Input, Radio, DatePicker, Checkbox } from 'antd';
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import WhsLogo from '../../assets/images/wsh-logo-white.svg';
import DeliveryDays from '../../components/DeliveryDays';
import SupplierDetails from '../../components/SupplierDetails';
import SearchResult from '../../components/SearchResult';

import './style.scss';

const { GOOGLE_MAPS_KEY } = require('../../core/constants').default;
const { MAP_STYLES } = require('../../core/utils/values').default;

export default function () {

    const { supplier, pendingRelationships, deliveryDays, match } = this.props;
    const { canSupply, page, differentPrimaryContact, copyPrevData } = this.state;
    const { params } = match;

    const tr = pendingRelationships.relationships[page - 1];
    // eslint-disable-next-line no-unused-vars
    const infoList = [
        {
            id: '001',
            title: 'Location name',
            descr: tr?.company?.location_name,
        },
        {
            id: '002',
            title: 'Price level',
            descr: tr?.price_level_value,
        },
        {
            id: '003',
            title: 'Sub account number',
            descr: tr?.company?.location_number,
        },
        {
            id: '004',
            title: 'Building name',
            descr: tr?.company?.address?.building_name,
        },
        {
            id: '005',
            title: 'Address 1',
            descr: tr?.company?.address?.address_1,
        },
        {
            id: '006',
            title: 'Address 2',
            descr: tr?.company?.address?.address_2,
        },
        {
            id: '007',
            title: 'Town',
            descr: tr?.company?.address?.town,
        },
        {
            id: '008',
            title: 'County',
            descr: tr?.company?.address?.county,
        },
        {
            id: '009',
            title: 'Postcode',
            descr: tr?.company?.address?.postcode,
        },
        {
            id: '010',
            title: 'Location telephone',
            descr: tr?.company?.location_telephone,
        },
        {
            id: '011',
            title: 'Location email',
            descr: tr?.company?.location_email,
        },
        {
            id: '012',
            title: 'Delivery instructions',
            descr: tr?.company?.delivery_instructions,
        },
    ];

    const supplierInfo = [
        {
            id: '001',
            title: 'Contact name:',
            value: tr?.supplier?.employee?.name,
        },
        {
            id: '002',
            title: 'Contact number:',
            value: tr?.supplier?.employee?.phone_number,
        },
        {
            id: '003',
            title: 'Contact email:',
            value: tr?.supplier?.employee?.email,
        },
        {
            id: '004',
            title: 'Area:',
            value: tr?.supplier?.address.county,
        },
    ];

    const navigation = [{ label: 'Supplier Hub' }];

    return (
        <Layout title="ApproveSupply"
            breadcrumb={navigation}
            showLogo
            className="approve-supply">
            <div className="sub-header">
                <div className="sub-header__inner">
                    <div className="header-title">
                        <div>
                            <h1 className="h-2 as-title">Review New Trading Relationship Request</h1>
                        </div>
                        <div className="header-logos">
                            {tr?.company?.img
                                ? (
                                    <div className="header-logo">
                                        <img style={{ height: '56px' }} src={tr?.company?.img} alt="brand-logo" />
                                    </div>
                                ) : null}
                            <div className="header-logo">
                                <img style={{ height: '56px' }} src={WhsLogo} alt="wsh-logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wrap">
                <div className="content-contr">
                    <div style={{ height: '46px' }} />
                    <div className="content__block tr-count">
                        <b>Supply Request { page } of { pendingRelationships.relationships.length }</b>
                    </div>
                    <div className="content__block">
                        <h1 className="h-2 as-description">{ tr?.employee ? `${tr?.employee?.name} from ` : ''} { tr?.company_name } would like to use { tr?.supplier?.name } as a supplier</h1>
                    </div>
                    <Form
                        layout="vertical"
                        name="trading-company-info"
                        initialValues={{ can_supply_location: true }}
                        onFinish={this.submitForm}>
                        <div className="content__block mt-5">
                            <div className="block__header">
                                <h4 className="h-4">Supply Details</h4>
                            </div>
                            <div>
                                {infoList.map(it => (
                                    <div key={it.id} className="list__item">
                                        <h5 className="h-5">{it.title}</h5>
                                        <p className="list__item-value">{it.descr}</p>
                                    </div>
                                ))}
                            </div>
                            <Form.Item
                                className="mt-3"
                                label="Are you able to supply to this location?"
                                name="can_supply_location">
                                <Radio.Group>
                                    <Radio onChange={() => this.setState({ canSupply: true })} value>Yes</Radio>
                                    <Radio onChange={() => this.setState({ canSupply: false })} value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {canSupply ? (
                                <>

                                    <Row className="mt-2" gutter={6}>
                                        <Col span={7}>
                                            <Form.Item
                                                label="Account number"
                                                name="account_number"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input valid value',
                                                    },
                                                ]}>
                                                <Input size="medium" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                label="Account activation date"
                                                name="account_activation_date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input valid value',
                                                    },
                                                ]}>
                                                <DatePicker format="DD-MM-YYYY" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            ) : ''}
                        </div>

                        {(!copyPrevData || page === 1) && canSupply ? (
                            <>
                                <DeliveryDays supplierDeliveryDays={deliveryDays} ref={this.DeliveryDays} />
                                <div className="content__block">
                                    <div className="block__header">
                                        <h4 className="h-4">Contact Information</h4>
                                    </div>
                                    <p>Here is the contact information we have on record for your trading company:</p>
                                    {supplierInfo.map(it => (
                                        <Row key={it.id} gutter={5}>
                                            <Col span={7}><b>{it.title}</b></Col>
                                            <Col span={7}>{it.value}</Col>
                                        </Row>
                                    ))}
                                    <Form.Item
                                        className="mt-3"
                                        name="different_primary_contact"
                                        label="Do you want to assign a different primary contact to just this account number?"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input valid value',
                                            },
                                        ]}>
                                        <Radio.Group onChange={(e) => this.setState({ differentPrimaryContact: e.target.value })}>
                                            <Radio value>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {differentPrimaryContact ? (
                                        <div className="mt-3">
                                            <Form.Item label="Name" name="alt_account_manager_name">
                                                <Input size="large" />
                                            </Form.Item>
                                            <Form.Item label="Email" name="alt_account_manager_email">
                                                <Input size="large" />
                                            </Form.Item>
                                            <Form.Item label="Phone" name="alt_account_manager_phone">
                                                <Input size="large" />
                                            </Form.Item>
                                        </div>
                                    ) : ''}

                                </div>

                                <div className="content__block">
                                    <div className="block__header">
                                        <h4 className="h-4">Your information</h4>
                                    </div>
                                    <div className="mt-3">
                                        <Form.Item
                                            label="Name"
                                            name="filled_by_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input valid value',
                                                },
                                            ]}>
                                            <Input size="large" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Email"
                                            name="filled_by_email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input valid value',
                                                },
                                            ]}>
                                            <Input size="large" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Phone"
                                            name="filled_by_phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input valid value',
                                                },
                                            ]}>
                                            <Input size="large" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="content__block">
                                    <div className="block__header">
                                        <h4 className="h-4">Your Trading Company WSH Listing</h4>
                                        <p className="block__header-subtitle">
                                            We use this information to help wsh companies find and use you, so please make sure
                                            your categories are correct and up to date if you would like to appear on searches.
                                        </p>
                                    </div>
                                    <div className="mt-3">
                                        <Row>
                                            <Col span={12}>
                                                <h5 className="listing-preview">Your full listing</h5>
                                                {supplier ? <SupplierDetails preview previewSupplierDetails={supplier} /> : ''}
                                            </Col>
                                            <Col span={12}>
                                                <h5 className="listing-preview">Your micro listing</h5>
                                                <SearchResult preview result={supplier} />
                                                <h5 className="listing-preview">Your service area</h5>
                                                <div className="service-area-map">
                                                    {supplier && supplier.latitude ? (
                                                        <GoogleMapReact
                                                            options={{
                                                                styles: MAP_STYLES,
                                                            }}
                                                            bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
                                                            defaultCenter={{ lat: parseFloat(supplier.latitude), lng: parseFloat(supplier.longitude) }}
                                                            defaultZoom={10}
                                                            yesIWantToUseGoogleMapApiInternals
                                                            onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)} />
                                                    ) : ''}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mt-5 text-center">
                                        <p>Issues with your Trading Company listing?</p>
                                        <Link to={`/trading-company-info/${params.id}/${params.token}`}>
                                            <Button>Update Company Listing</Button>
                                        </Link>
                                    </div>
                                </div>
                            </>
                        ) : ''}
                        {page !== 1 ? (
                            (
                                <div className="content__block">
                                    <Row>
                                        <Col span={3}>
                                            <Checkbox
                                                onChange={() => this.setState({ copyPrevData: !copyPrevData })}
                                                checked={copyPrevData}
                                                defaultChecked
                                                className="mt-1" />
                                        </Col>
                                        <Col span={21}>
                                            <div>
                                                <h4 className="h-4">Copy same information from previous request</h4>
                                                <p className="block__header-subtitle">
                                                    Would you like to use the same accounting, delivery, contact and listing information of the last request you approved?
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        ) : ''}
                        <div className="mt-5 content-terms">
                            <Row justify="start">
                                <Form.Item
                                    name="information_correct"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) => (
                                                value ? Promise.resolve() : Promise.reject(new Error('Please confirm information is correct'))),

                                        },
                                    ]}>
                                    <Checkbox className="mt-1">I confirm our above-listed trading company information is correct</Checkbox>
                                </Form.Item>
                            </Row>
                        </div>
                        <div className="mt-5 text-center">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="primary wide">
                                Submit and {page < pendingRelationships.relationships.length ? 'View Next' : 'Finish'}
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Layout>
    );
}
