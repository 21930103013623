import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Pagination, Button, Modal, Form, Input, Row, Col } from 'antd';
import moment from 'moment';

import './style.scss';
import '../../styles/table.scss';
import Layout from '../../components/layout/Layout/index';
import Icon from '../../elements/Icon';

const { TextArea } = Input;

export default function () {
    const { auditLogs: { count, data }, user } = this.props;
    const { page, perPage, auditLog } = this.state;

    const rawUserRole = (user.userData && user.userData.role && user.userData.role.name) || 'user';
    const userRole = rawUserRole.toLowerCase();
    if (userRole !== 'purchasing manager' && userRole !== 'supply chain manager' && userRole !== 'admin') {
        return 'Not authorized';
    }

    const navigation = [{ label: 'Supplier Hub' }, { label: 'Audit Log' }];
    const config = [
        {
            dataIndex: 'date',
            title: 'Date',
            sorter: true,
            render: d => (d ? moment(d).format('DD-MM-YYYY h:mm') : ''),
        },
        {
            dataIndex: 'category', title: 'Category', sorter: true,
        },
        {
            dataIndex: 'applies_to', title: 'Applies to',
        },
        {
            dataIndex: 'action', title: 'Action', sorter: true,
        },
        {
            dataIndex: 'user', title: 'User updated', sorter: true,
        },
        {
            dataIndex: 'id',
            title: '',
            render: (id) => (
                <div className="column-actions">
                    <Button
                        className="underlined toolbar-button"
                        onClick={() => this.openModal(id)}>
                        <span>View</span>
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Layout
            title="AuditLog"
            breadcrumb={navigation}
            showMenu
            style={{ height: '90vh' }}
            className="audit-log">
            <div className="sub-header">
                <div className="sub-header__inner">
                    <div className="backlink">
                        <Link to="/">
                            <Icon name="arrow-left" />
                        </Link>
                        <h1 className="h-2" style={{ color: 'white' }}>Audit Log</h1>
                    </div>
                </div>
            </div>
            <div className="default-page-wrapper">
                <div className={`contentblock ${data && data.length === perPage ? 'expandRow' : ''}`} style={{ height: '100%', width: '80%' }}>
                    {data && (
                        <Table
                            columns={config}
                            dataSource={data.map((d) => ({ ...d, key: d.id }))}
                            onChange={this.tableChanged}
                            pagination={false} />
                    )}

                    <div className="paginationContainer mt-3">
                        <Pagination
                            current={page}
                            total={count}
                            onChange={this.handlePageChange}
                            pageSize={perPage}
                            showSizeChanger={false} />
                    </div>
                </div>
            </div>

            {auditLog && (
                <Modal
                    visible={this.state.modalVisibility}
                    title="Audit Log"
                    onCancel={this.closeModal}
                    className="audit-log-modal"
                    footer={[
                        <Button key="back" type="primary" onClick={this.closeModal}>
                            Return
                        </Button>,
                    ]}>
                    <div>
                        <Form layout="vertical" ref={this.myRef}>
                            <div className="modal-div">
                                <Row>
                                    <Col span={12}>Date</Col>
                                    <Col span={12}>{auditLog.date}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}>Category</Col>
                                    <Col span={12}>{auditLog.category}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}>Applies to</Col>
                                    <Col span={12}>{auditLog.applies_to}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}>Action</Col>
                                    <Col span={12}>{auditLog.action}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}>User updated</Col>
                                    <Col span={12}>{auditLog.user}</Col>
                                </Row>
                                <Form.Item label="Before change" name="before_change" className="mt-2">
                                    <TextArea style={{ height: 152 }} disabled={false} />
                                </Form.Item>
                                <Form.Item label="After change" name="after_change" className="mt-2">
                                    <TextArea style={{ height: 152 }} disabled={false} />
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </Modal>
            )}
        </Layout>
    );
}
