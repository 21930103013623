import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual, findIndex, isEmpty, pluck } from 'underscore';

import ReactGA from 'react-ga4';
import history from '../../core/utils/history';

import * as globalActions from '../../core/global/globalActions';
import * as supplierActions from '../../core/supplier/supplierActions';
import * as helpActions from '../../core/help/helpActions';
import * as categoryActions from '../../core/category/categoryActions';

import DOM from './tradingCompanyInfo';

class TradingCompanyInfo extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            tags: [],
            selectedMarker: {},
            mapSliderValue: 0,
            markers: [],
            supplierCategories: [],
            updateSupplierCategories: [],
            tagInputVisible: false,
            newTagValue: '',
            agreeInfoCorrect: true,
            agreeToShareData: false,
        };

        this.DeliveryDays = React.createRef();
    }

    componentDidMount() {
        const { actions, match, supplier } = this.props;
        const { params } = match;

        actions.getPendingRelationshipsRequest({ supplier: +params.id, token: params.token });
        actions.getCategoriesRequest();
        this.setState({ tags: supplier.tags });
        ReactGA.send({ hitType: 'pageview', page: '/trading-company-info', title: 'Trading Company Info' });
    }

    componentDidUpdate(prevProps, prevState) {
        const { supplier, pendingRelationships, supplierAreas } = this.props;
        const { maps, map } = this.state;

        if (!isEqual(prevProps.pendingRelationships, pendingRelationships)) {
            this.setState({ tags: supplier.tags });
        }

        if (
            pendingRelationships
            && supplierAreas
            && maps
            && (!isEqual(prevProps.supplierAreas, supplierAreas) || !isEqual(prevState.maps, maps))
        ) {
            maps.event.addListener(map, 'click', (e) => {
                this.addMarker(e.latLng, 1000, true);
            });

            supplierAreas.map(sa => this.addMarker({ lat: parseFloat(sa.latitude), lng: parseFloat(sa.longitude) }, parseInt(sa.radius, 10) * 1000));
        }
    }

    handleApiLoaded = (map, maps) => {
        this.setState({ map, maps });
    };

    addMarker = (position, radius, freshMarker) => {
        const { map, maps, markers } = this.state;

        const marker = new maps.Marker({
            map,
            position,
        });

        marker.Circle = new maps.Circle({
            strokeColor: '#27c9e5',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#27c9e5',
            fillOpacity: 0.3,
            map,
            center: position,
            radius,
        });

        marker.addListener('click', () => {
            this.setState({ selectedMarker: marker, mapSliderValue: marker.Circle.radius / 1000 });
        });

        markers.push(
            marker,
        );

        this.setState({ markers });
        if (freshMarker) {
            this.setState({ selectedMarker: marker, mapSliderValue: marker.Circle.radius / 1000 });
        }
    }

    removeMarker = () => {
        const { selectedMarker, markers } = this.state;
        if (!isEmpty(selectedMarker)) {
            selectedMarker.Circle.setMap(null);
            selectedMarker.setMap(null);

            const markerIndex = findIndex(markers, (val) => val.position === selectedMarker.position);
            if (markerIndex !== -1) {
                markers.splice(markerIndex, 1);
            }
            this.setState({ markers, selectedMarker: {} });
        }
    }

    setMarkerRadius = (e) => {
        const { selectedMarker } = this.state;
        selectedMarker.Circle.setRadius(e * 1000);
        this.setState({ mapSliderValue: e });
        this.getNewSupplierAreas();
    }

    getNewSupplierAreas = () => {
        const { markers } = this.state;
        const { match } = this.props;
        const finalSupplierAreas = [];

        markers.forEach(marker => {
            finalSupplierAreas.push({
                supplier_id: +match.params.id,
                latitude: marker.position.lat(),
                longitude: marker.position.lng(),
                radius: marker.Circle.radius / 1000,
            });
        });

        return finalSupplierAreas;
    }

    updateSupplierCategories = (supplierCategories) => {
        const combinedCategories = [];

        supplierCategories.forEach(scat => {
            if (scat.isPrimary) {
                combinedCategories.push({ catId: scat.id, isPrimary: true });
            } else {
                combinedCategories.push({ catId: scat.id });
            }
            if (scat.subcatId) {
                combinedCategories.push({ catId: scat.subcatId });
            }
        });
        this.setState({ updatedSupplierCategories: combinedCategories });
    }

    showTagInput = () => {
        this.setState({ tagInputVisible: true });
    };

    handleTagInput = (e) => {
        this.setState({ newTagValue: e.target.value });
    }

    handleTagSubmit = () => {
        const { newTagValue, tags } = this.state;
        tags.push(newTagValue);

        this.setState({ tags, tagInputVisible: false });
    }

    removeTag = (tagKey) => {
        const { tags } = this.state;
        const currentTags = tags;
        currentTags.splice(tagKey, 1);

        this.setState({ tags: currentTags });
    }

    submitForm = vals => {
        const { actions, match } = this.props;
        const { tags, updatedSupplierCategories } = this.state;
        const data = {};

        const primary_category = updatedSupplierCategories.find(cat => cat.isPrimary).catId;
        const categories = pluck(updatedSupplierCategories.filter(cat => !cat.isPrimary), 'catId');

        data.supplier = match.params.id;
        data.token = match.params.token;
        data.formData = vals;
        data.supplyAreas = this.getNewSupplierAreas();
        data.deliveryDays = this.DeliveryDays.current.state.deliveryDays;
        data.categories = categories;
        data.primaryCategory = primary_category;
        data.tags = tags;

        actions.createSupplierDetailChangeRequest(data);

        history.push(`/approve-supply/${match.params.id}/${match.params.token}`);
    };

    render() {
        const { pendingRelationships } = this.props;

        if (pendingRelationships) {
            return this.view();
        }

        return 'Access denied';
    }
}

TradingCompanyInfo.defaultProps = {
    pendingRelationships: null,
    supplier: null,
    deliveryDays: null,
    supplierAreas: null,
    allCategories: null,
    allSubcategories: null,
    supplierCategories: null,
    supplierSubcategories: null,
    primaryCategory: null,
};

TradingCompanyInfo.propTypes = {
    actions: PropTypes.object.isRequired,
    global: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    pendingRelationships: PropTypes.object,
    supplier: PropTypes.object,
    deliveryDays: PropTypes.array,
    supplierAreas: PropTypes.array,
    allCategories: PropTypes.array,
    allSubcategories: PropTypes.array,
    supplierCategories: PropTypes.array,
    supplierSubcategories: PropTypes.array,
    primaryCategory: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        global: state.global,
        pendingRelationships: state.supplier.pendingRelationships,
        supplier: state.supplier.pendingRelationships.supplier,
        deliveryDays: state.supplier.pendingRelationships ? state.supplier.pendingRelationships.delivery_days : null,
        supplierAreas: state.supplier.pendingRelationships ? state.supplier.pendingRelationships.supplier_areas : null,
        allCategories: state.category.categories,
        supplierCategories: state.supplier.pendingRelationships ? state.supplier.pendingRelationships.supplier_categories : null,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...supplierActions,
                ...categoryActions,
                ...helpActions,
            },
            dispatch,
        ),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TradingCompanyInfo);
