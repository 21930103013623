import React from 'react';
import { Row, Col, Button, Switch } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { isNull, mapObject } from 'underscore';
import moment from 'moment';
import Icon from '../../elements/Icon';

import './style.scss';

const { USE_SUPPLIER_FORM_LINK, UNAPPROVED_SUPPLIER_LINK } = require('../../core/constants').default;

export default function () {
    const { preview, previewSupplierDetails } = this.props;
    const supplier = preview ? previewSupplierDetails : this.props.supplier;
    const supplierDetails = this.state.showParentDetails ? supplier : supplier;
    const windows = [];
    if (supplier.delivery_window) {
        mapObject(supplier.delivery_window, (value, key) => {
            windows.push((
                <span key={key}>
                    <span>{key}</span>
                    <span className="ml-2">{value}</span>
                    <br />
                </span>
            ));
        });
    }

    const isEmpty = (string) => {
        if (string === undefined || string === null || string.trim().length === 0) {
            return true;
        }

        return false;
    };
    const getUseSupplierButton = (isApproved, fullWidth = false) => {
        if (!supplierDetails.direct) {
            return null;
        }

        if (supplierDetails?.parent_id) {
            return null;
        }

        return (
            <a href={isApproved ? USE_SUPPLIER_FORM_LINK : UNAPPROVED_SUPPLIER_LINK} target="_blank" rel="noopener noreferrer">
                <Button className={`btn primary ${fullWidth ? 'w100' : ''}`}>Use Supplier</Button>
            </a>
        );
    };

    const supplierAddress = [
        { index: 0, value: supplierDetails?.address?.building_name },
        { index: 1, value: supplierDetails?.address?.address_1 },
        { index: 2, value: supplierDetails?.address?.address_2 },
        { index: 3, value: supplierDetails?.address?.town },
        { index: 4, value: supplierDetails?.address?.county },
        { index: 5, value: supplierDetails?.address?.postcode },
        { index: 6, value: supplierDetails?.address?.country },
    ];

    const isApproved = !!supplierDetails?.approved;

    return (
        <Row type="flex" style={{ height: '100%' }}>
            <Col style={{ paddingLeft: '0' }} className="supplier-details-container-col">
                <div className="sidebar-results">
                    <div className="details__header">
                        <div className="heading">
                            <div onClick={!preview ? () => this.props.backToSearch() : () => {}} className="heading__link">
                                <Icon name="arrow-left" />
                            </div>
                            <span className="heading__title">{supplier.name !== undefined ? supplier.name : ''}</span>
                            <span className="heading__info">
                                <Icon name="location" />
                                <span>{supplier.distance ? supplier.distance.toString().slice(0, 4) : ''} KM</span>
                            </span>
                        </div>
                        <a href={`tel:${supplier.phone_number}`} className="link" style={{ marginTop: 6 }}>
                            {supplier.phone_number !== undefined ? supplier.phone_number : ''}
                        </a>
                        <br />
                        <a href={`mailto:${supplier.email !== undefined ? supplier.email : ''}`} className="link" style={{ marginTop: 4 }}>
                            {supplier.email !== undefined ? supplier.email : ''}
                        </a>
                        <Row type="flex" align="middle" gutter={8} className="mt-2">
                            <Col>
                                {getUseSupplierButton(isApproved)}
                            </Col>
                            <Col>
                                <a href={supplier.website !== undefined ? supplier.website : ''} rel="noopener noreferrer" target="_blank"><Button disabled={!supplier.website} className="btn secondary">Visit Website</Button></a>
                            </Col>
                            <Col>
                                <Button
                                    className="btn iconned"
                                    // eslint-disable-next-line max-len
                                    href={`mailto:?subject=Supplier%20Recommendation%20-%20${supplier.name}&body=Hi%2C%0A%0AI'd%20like%20to%20recommend%20you%20a%20Supplier%20to%20use%20on%20WSH%20Supplier%20Hub%2C%20details%20below%3A%0A%0AName%3A%20${supplier.name}%0AWebsite%3A%20${supplier.website ? supplier.website : 'none'}`}>
                                    <Icon name="socials" />
                                </Button>
                            </Col>
                            {supplier.social_media_link
                                ? (
                                    <Col>
                                        <Button
                                            className="btn iconned"
                                            target="_blank"
                                            href={supplier.social_media_link}>
                                            <MessageOutlined style={{ fontSize: 20 }} />
                                        </Button>
                                    </Col>
                                ) : null}
                        </Row>
                    </div>
                    <div className="details__body">
                        {(typeof supplierDetails.supplier_areas !== 'undefined' && supplierDetails.supplier_areas !== null && supplierDetails.supplier_areas.length !== null && supplierDetails.supplier_areas.length > 0) ? (
                            <Button onClick={this.props.showSupplierAreas} className="btn secondary">View Delivery Area</Button>
                        ) : ''}
                        <div className="info">
                            <div className="info__item column">
                                <h5 className="h-5">About</h5>

                                { this.renderAbout(supplierDetails) }

                            </div>
                            <div className="info__item">
                                <h5 className="h-5">Added</h5>
                                <p>{supplierDetails.added_on ? moment(supplierDetails.added_on).format('DD-MM-YYYY') : ''}</p>
                            </div>
                            {/* <div className="info__item">
                                <h5 className="h-5">Account number</h5>
                                <p>{supplierDetails.account_number !== undefined ? supplierDetails.account_number : ''}</p>
                            </div> */}
                            <>
                                {/* <h5 className="h-5 account-manager-heading">Account manager:</h5>
                                <div className="info__item multiple">
                                    <div className="info__item">
                                        <h5 className="h-5">Name</h5>
                                        <p>{supplierDetails.manager !== undefined ? supplierDetails.manager.name : ''}</p>
                                    </div> */}
                                <div className="info__item">
                                    <h5 className="h-5">Email</h5>
                                    <p>
                                        <a rel="noopener noreferrer" href={`mailto:${supplierDetails.manager !== undefined ? supplierDetails.manager.email : ''}`} className="link">
                                            {supplierDetails.manager !== undefined ? supplierDetails.manager.email : ''}
                                        </a>
                                    </p>
                                </div>
                                {/* <div className="info__item">
                                        <h5 className="h-5">Phone</h5>
                                        <p>
                                            <a rel="noopener noreferrer" href={`tel:${supplierDetails.manager !== undefined ? supplierDetails.manager.phone_number : ''}`} className="link">
                                                {supplierDetails.manager !== undefined ? supplierDetails.manager.phone_number : ''}
                                            </a>
                                        </p>
                                    </div>
                                </div> */}
                            </>

                            <div className="info__item">
                                <h5 className="h-5">Creditor ID</h5>
                                <p>{supplierDetails.creditor !== undefined ? supplierDetails.creditor : ''}</p>
                            </div>
                            <div className="info__item">
                                <h5 className="h-5">Address</h5>
                                {supplierDetails.address !== undefined ? (
                                    <address className="address">
                                        {supplierAddress.map(it => {
                                            if (!isEmpty(it.value)) {
                                                return <div key={it.index}>{it.value}</div>;
                                            }
                                            return null;
                                        })}
                                    </address>
                                ) : ''}
                            </div>
                            <div className="info__item">
                                <h5 className="h-5">Categories</h5>
                                <p>{supplierDetails.categories !== undefined && !isNull(supplierDetails.categories) ? this.formatCategories(supplierDetails.categories).map(category => category) : ''}</p>
                            </div>
                            <div className="info__item">
                                <h5 className="h-5">Tags</h5>
                                <p>{supplierDetails.tags !== undefined && !isNull(supplierDetails.tags) ? this.formatTags(supplierDetails.tags).map(tag => tag) : ''}</p>
                            </div>
                            {/* <div className="info__item">
                                <h5 className="h-5">Delivery window</h5>
                                <p>{windows}</p>
                            </div> */}

                            {supplier.show_parent_details_toggle ? (
                                <div className="info__item show-parent">
                                    <h5 className="h-5">Show parent info</h5>
                                    <Switch defaultChecked onChange={() => this.toggleParentDetails(supplier.parent_id, supplier.id)} />
                                </div>
                            ) : ''}

                        </div>

                        <div className="mt-3">
                            {getUseSupplierButton(isApproved, true)}
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
