import React from 'react';
import PropTypes from 'prop-types';
import spriteSrc from '../../assets/images/sprite.svg';

const Icon = props => (
    <svg className={`icon icon-${props.name}  ${props.className} ${!props.visible ? 'hidden' : ''}`} style={props.style || undefined}>
        <use xlinkHref={`${spriteSrc}#icon-${props.name}`} />
    </svg>
);

Icon.defaultProps = {
    name: '',
    className: '',
    visible: true,
    style: undefined,
};

Icon.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    visible: PropTypes.bool,
    style: PropTypes.object,
};
export default Icon;
