import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Divider, Row, Space, Tabs } from 'antd';
import GoogleMapReact from 'google-map-react';
import Moment from 'moment';
import Layout from '../../components/layout/Layout';
import Icon from '../../elements/Icon';
import './style.scss';

const { TabPane } = Tabs;

const { GOOGLE_MAPS_KEY } = require('../../core/constants').default;
const { MAP_STYLES } = require('../../core/utils/values').default;

export default function () {

    const toolbar = [{ label: 'Help', iconName: 'question', onClick: this.props.actions.openHelpModal }];
    const navigation = [
        { label: 'Supplier Hub', link: '/search' },
        { label: 'Supply Chain Tools' },
        { label: 'Use Supplier Requests', link: '/supplier-requests' },
        { label: 'View Request' },
    ];

    const { tradingRelationship } = this.props;

    const checkDate = (Userdate) => {
        const ToDate = new Date();
        const udate = new Date(Userdate).getTime();
        if (ToDate.getTime() >= udate) {
            return 'Yes';
        }
        return 'No';

    };

    const supplyLocationStatus = (status) => {
        if (status === 60) {
            return 'Yes';
        } if (status === 70) {
            return 'No';
        }
        return '';

    };

    const executeMapScroll = () => {
        const elmnt = window.document.getElementById('mapRef');
        elmnt.scrollIntoView(true);
    };

    const stime = (start, end) => {
        if (start !== null && end !== null) {
            return `${Moment(start, 'HH:mm').format('HH:mm')} - ${Moment(end, 'HH:mm').format('HH:mm')}`;
        }
        return null;
    };

    const delWindow = tradingRelationship?.supplier.delivery_days.map(dday => (
        <span className="title-case d-inline" key={dday.id}>{dday.day}: {dday.all_day === 1 ? ' All day ' : stime(dday.start, dday.end) }</span>
    ));

    const supplierDetailsArr = [
        {
            id: '001',
            title: 'Location name',
            value: tradingRelationship?.company_name,

        },
        {
            id: '002',
            title: 'Price level',
            value: tradingRelationship?.company?.priceLevels[0]?.name,
        },
        {
            id: '003',
            title: 'Account number',
            value: tradingRelationship?.supplier_account_number,
        },
        {
            id: '004',
            title: 'Building name',
            value: tradingRelationship?.supplier?.address.building_name,
        },
        {
            id: '005',
            title: 'Address 1',
            value: tradingRelationship?.supplier?.address.address_1,
        },
        {
            id: '006',
            title: 'Address 2',
            value: tradingRelationship?.supplier?.address.address_2,
        },
        {
            id: '007',
            title: 'Town',
            value: tradingRelationship?.supplier?.address?.town,
        },
        {
            id: '008',
            title: 'County',
            value: tradingRelationship?.supplier?.address?.county,
        },
        {
            id: '009',
            title: 'Postcode',
            value: tradingRelationship?.supplier?.address?.postcode,
        },
        {
            id: '010',
            title: 'Country',
            value: tradingRelationship?.supplier?.address?.country,
        },
        {
            id: '011',
            title: 'Location telephone',
            value: tradingRelationship?.supplier?.phone,
        },
        {
            id: '012',
            title: 'Location email',
            value: tradingRelationship?.supplier?.email,
            titleCase: false,
        },
        {
            id: '014',
            title: 'Are you able to supply this location?',
            value: supplyLocationStatus(tradingRelationship?.status),
            breakRow: true,
            map: true,
        },
    ];

    const accountingArr = [
        {
            id: '001',
            title: 'Account number',
            value: [tradingRelationship?.supplier_account_number],
            breakRow: true,
        },
        {
            id: '002',
            title: 'Account activation date',
            value: [Moment(tradingRelationship?.start_date).format('DD-MM-YYYY')],
            breakRow: true,
        },
        {
            id: '003',
            title: 'Delivery days',
            value: [delWindow],
            breakRow: true,
        },
    ];

    const contactInfoArr = [
        {
            id: '001',
            title: 'Contact name',
            value: [tradingRelationship?.supplier?.employee?.name],
        },
        {
            id: '002',
            title: 'Contact number',
            value: [tradingRelationship?.supplier?.employee?.phone_number],
        },
        {
            id: '003',
            title: 'Contact email',
            value: [tradingRelationship?.supplier?.employee?.email],
        },
        {
            id: '004',
            title: 'Area',
            value: [tradingRelationship?.supplier?.address?.town],
        },
        {
            id: '005',
            title: 'Do you want to assign a different primary contact to just this account number?',
            value: ['Yes'],
            class: 'mt-3',
            breakRow: true,
        },
        {
            id: '006',
            title: 'Contact name',
            value: [tradingRelationship?.supplier?.employee?.name],
            class: 'mt-4',
            breakRow: true,
        },
    ];

    const yourInfoArr = [
        {
            id: '001',
            title: 'Your name',
            value: [tradingRelationship?.fill_by?.name],
            class: 'mt-4',
            breakRow: true,
        },
        {
            id: '002',
            title: 'Your contact telephone number',
            value: [tradingRelationship?.fill_by?.phone_number],
            class: 'mt-4',
            breakRow: true,
        },
        {
            id: '003',
            title: 'Your email',
            value: [tradingRelationship?.fill_by?.email],
            class: 'mt-4',
            breakRow: true,
            titleCase: false,
        },
    ];

    const fullListingArr = [
        {
            id: '000',
            title: 'About',
            value: [tradingRelationship?.supplier?.description],
            breakRow: true,
        },
        {
            id: '001',
            title: 'Added',
            value: [Moment(tradingRelationship?.supplier?.added_on).format('DD-MM-YYYY')],
            class: 'mt-4',
        },
        {
            id: '002',
            title: 'Account number',
            value: tradingRelationship?.supplier?.supplier_account_number,
        },
        {
            id: '003',
            title: tradingRelationship?.supplier?.employee?.job_title,
        },
        {
            id: '004',
            title: 'Name',
            value: [tradingRelationship?.supplier?.employee?.name],
            class: 'sub-item',
        },
        {
            id: '005',
            title: 'Email',
            value: [
                <a href="mailto:johnsmith@aquinews.com" className="link  fz-14">
                    {tradingRelationship?.supplier?.employee?.email}
                </a>,
            ],
            class: 'sub-item',
        },
        {
            id: '006',
            title: 'Phone',
            value: [
                <a href="tel:0121 724 0447" className="link fz-14">
                    {tradingRelationship?.supplier?.employee?.phone_number}
                </a>,
            ],
            class: 'sub-item',
        },
        {
            id: '007',
            title: 'Creditor ID',
            value: [tradingRelationship?.supplier?.creditor_id],
        },
        {
            id: '008',
            title: 'Address',
            value: [`${tradingRelationship?.supplier?.address?.building_name ?? ''}  ${tradingRelationship?.supplier.address.address_1}
                    ${tradingRelationship?.supplier.address.address_2} ${tradingRelationship?.supplier.address.county} ${tradingRelationship?.supplier.address.postcode} ${tradingRelationship?.supplier.address.country}`],
        },
        {
            id: '009',
            title: 'Categories',
            value: [tradingRelationship?.supplier?.categories.map(cat => (
                <span className="title-case" key={cat.id}> {cat.name} </span>
            ))],
        },
        {
            id: '010',
            title: 'Tags',
            value: [tradingRelationship?.supplier?.tags.map(cat => (
                <span className="title-case" key={cat.id}> {cat.name}     </span>
            ))],
        },
        {
            id: '011',
            title: 'Delivery windows',
            value: [delWindow],
        },
    ];

    const locationDetailsArr = [
        {
            id: '001',
            title: 'Location name',
            value: tradingRelationship?.company?.location_name,
        },
        {
            id: '002',
            title: 'Sub account number',
            value: tradingRelationship?.company?.sub_account_number,
        },
        {
            id: '004',
            title: 'Building name',
            value: tradingRelationship?.company?.address.building_name,
        },
        {
            id: '005',
            title: 'Address 1',
            value: tradingRelationship?.company?.address?.address_1,
        },
        {
            id: '006',
            title: 'Address 2',
            value: tradingRelationship?.company?.address?.address_2,
        },
        {
            id: '007',
            title: 'Town',
            value: tradingRelationship?.company?.address?.town,
        },
        {
            id: '008',
            title: 'County',
            value: tradingRelationship?.company?.address?.country,
        },
        {
            id: '009',
            title: 'Postcode',
            value: tradingRelationship?.company?.address?.postcode,
        },
        {
            id: '010',
            title: 'Country',
            value: tradingRelationship?.company?.address?.country,
        },
        {
            id: '011',
            title: 'Location telephone',
            value: tradingRelationship?.company?.location_telephone,
        },
        {
            id: '012',
            title: 'Location email',
            value: tradingRelationship?.company?.location_email,
        },
        {
            id: '013',
            title: 'Delivery instructions',
            value: tradingRelationship?.company?.delivery_instructions,
            map: true,
        },
    ];

    const SupplierViewPage = () => (
        <Link to="/supplier-requests">
            <Button type="primary" htmlType="submit" className="primary wide">
                Ok
            </Button>
        </Link>
    );

    return (
        <Layout
            title={<div>Welcome</div>}
            showMenu
            toolbar={toolbar}
            headerTitle="Your requests"
            floatingHeader
            breadcrumb={navigation}>
            <div className="tabs-wrap">
                <div className="bg_palegrey">
                    <div className="tabs-contr">
                        <div style={{ height: '102px' }} />
                    </div>
                </div>
                <Tabs animated={false} className="tabs-custom" centered>
                    <TabPane tab="Location Information" key="location_information">
                        <div className="content__block narrow mt-5">
                            <div className="block__header">
                                <h4 className="h-4">Supplier Selected</h4>
                            </div>
                            <div className="mt-3">
                                <div className="list__item list__item-vert">
                                    <h5 className="h-5">Name</h5>
                                    <div className="list__item-val">
                                        <Space size={16}>
                                            <p className="title-case">{tradingRelationship?.company_name}</p>
                                            <Icon name="checkmark" className="c_green" />
                                        </Space>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <h5 className="h-5">Primary category of supply</h5>

                                    <div className="list__item mt-2">
                                        <h5 className="h-5">Category:</h5>
                                        <div className="list__item-val">
                                            <p>{tradingRelationship?.category?.name}</p>
                                        </div>
                                    </div>
                                    <div className="list__item">
                                        <h5 className="h-5">Subcategory:</h5>
                                        <div className="list__item-val">
                                            <p>{tradingRelationship?.category?.parent_id ?? 'All'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="list__item list__item-vert">
                                        <h5 className="h-5">Describe required product or service</h5>
                                        <div className="list__item-val mt-2">
                                            <p>{tradingRelationship?.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="list__item list__item-vert">
                                        <h5 className="h-5">Has supply started?</h5>
                                        <div className="list__item-val mt-2">
                                            <p>{ checkDate(tradingRelationship?.start_date) }</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="list__item list__item-vert">
                                        <h5 className="h-5">When should supply start?</h5>
                                        <div className="list__item-val mt-2">
                                            <p> {Moment(tradingRelationship?.start_date).format('DD-MM-YYYY')} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content__block narrow">
                            <div className="block__header">
                                <h4 className="h-4">Requested By</h4>
                            </div>
                            <div className="mt-3">
                                <div className="list__item list__item-vert mt-4">
                                    <h5 className="h-5">Name</h5>
                                    <div className="list__item-val">{tradingRelationship?.employee?.name}</div>
                                </div>
                                <div className="list__item list__item-vert mt-4">
                                    <h5 className="h-5">Contact number:</h5>
                                    <div className="list__item-val">
                                        <p>{tradingRelationship?.employee?.phone_number}</p>
                                    </div>
                                </div>
                                <div className="list__item list__item-vert mt-4">
                                    <h5 className="h-5">Email:</h5>
                                    <div className="list__item-val">
                                        <p>{tradingRelationship?.employee?.email}</p>
                                    </div>
                                </div>
                                <div className="list__item list__item-vert mt-4">
                                    <h5 className="h-5">Job title:</h5>
                                    <div className="list__item-val">
                                        <p>{tradingRelationship?.employee?.job_title}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content__block narrow">
                            <div className="block__header">
                                <h4 className="h-4">Your Company and Location</h4>
                            </div>
                            <div className="mt-3">
                                <div className="list__item list__item-vert mt-3">
                                    <h5 className="h-5">Trading company</h5>
                                    <div className="list__item-val">{tradingRelationship?.company?.title}</div>
                                </div>
                                <div className="list__item list__item-vert mt-4">
                                    <h5 className="h-5">Location number</h5>
                                    <div className="list__item-val">
                                        <p>{tradingRelationship?.company?.location_number}</p>
                                    </div>
                                </div>
                            </div>
                            <h5 className="h-5 mt-4">Location details (for Location {tradingRelationship?.company?.location_number})</h5>
                            <div className="list">
                                {locationDetailsArr.map(it => (
                                    <div key={it.id} className={`list__item ${it.breakRow && 'list__item-vert'}`}>
                                        <h5 className="h-5">{it.title}:</h5>
                                        <div className="list__item-val">
                                            <p>{it?.value}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                            <SupplierViewPage />
                        </div>
                    </TabPane>
                    <TabPane tab="Supplier Information" key="supplier_information">
                        <div className="content__block narrow mt-5">
                            <div className="block__header">
                                <h4 className="h-4">Supplier Details</h4>
                            </div>
                            <div className="list">
                                {supplierDetailsArr.map(it => (
                                    <div key={it.id} className={`list__item ${it.breakRow && 'list__item-vert'}`}>
                                        <h5 className="h-5">{it.title}:</h5>
                                        <div className="list__item-val">
                                            <p className={(it.titleCase === false ? '' : 'title-case')}> {it.value}</p>
                                            {it.map && (
                                                <Button onClick={executeMapScroll} className="secondary mb-4 mt-1">
                                                    <Icon name="location" className="fz-10" />
                                                    <span style={{ marginLeft: 2 }}>Map</span>
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="content__block narrow">
                            <div className="block__header">
                                <h4 className="h-4">Accounting and Delivery Information</h4>
                            </div>
                            <div className="list">
                                {accountingArr.map(it => (
                                    <div key={it.id} className={`list__item mt-4 ${it.breakRow && 'list__item-vert'}`}>
                                        <h5 className="h-5">{it.title}</h5>
                                        <div className="list__item-val">
                                            {it.value.map(p => (
                                                <p className="title-case" key={p}>{p}</p>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="content__block narrow">
                            <div className="block__header">
                                <h4 className="h-4">Contact information</h4>
                            </div>
                            <h5 className="h-5">
                                Here is the contact information we have on record for your trading company:
                            </h5>

                            <div className="list">
                                {contactInfoArr.map(it => (
                                    <div
                                        key={it.id}
                                        className={`list__item ${it.class} ${it.breakRow && 'list__item-vert'}`}>
                                        <h5 className="h-5">{it.title}</h5>
                                        <div className="list__item-val">
                                            {it.value.map(p => (
                                                <p key={p}>{p}</p>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="content__block narrow">
                            <div className="block__header">
                                <h4 className="h-4">Your information</h4>
                            </div>
                            <div className="list">
                                {yourInfoArr.map(it => (
                                    <div
                                        key={it.id}
                                        className={`list__item ${it.class} ${it.breakRow && 'list__item-vert'}`}>
                                        <h5 className="h-5">{it.title}</h5>
                                        <div className="list__item-val">
                                            {it.value.map(p => (
                                                <p className={(it.titleCase === false ? '' : 'title-case')} key={it.id}>{p}</p>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="content__block narrow" style={{ paddingLeft: 24, paddingRight: 24 }}>
                            <div className="block__header">
                                <h4 className="h-4">Your Trading Company wsh listing</h4>
                            </div>
                            <Row gutter={24}>
                                <Col span={13}>
                                    <h5 className="h-5">Your full listing</h5>
                                    <div className="info-area">
                                        <Space size={4} align="center">
                                            <h5 className="h-5">{tradingRelationship?.supplier_name}</h5>
                                            <Icon name="location" className="fz-10" />
                                            <span className="fz-12">1.3 ml</span>
                                        </Space>
                                        <br />
                                        <a href="tel:{tradingRelationship?.supplier.phone}" className="link fz-14">
                                            {tradingRelationship?.supplier?.phone}
                                        </a>
                                        <br />
                                        <a href="mailto:{tradingRelationship?.supplier.email}" className="link  fz-14">
                                            {tradingRelationship?.supplier?.email}
                                        </a>
                                        <Divider className="mt-2 mb-2" />
                                        <Button className="secondary">View Delivery Area</Button>
                                        <div className="mt-2">
                                            {fullListingArr.map(it => (
                                                <div
                                                    key={it.id}
                                                    className={`list__item ${it.class ? it.class : ''} ${it.breakRow
                                                        ? 'list__item-vert' : ''}`}>
                                                    <h5 className="h-5">{it.title}</h5>
                                                    <div className="list__item-val">
                                                        {it.value?.map(p => (
                                                            <p key={p}>{p}</p>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                                <Col span={11}>
                                    <h5 className="h-5 text-center">Your micro listing</h5>
                                    <div className="info-area">
                                        <h5 className="h-4">{tradingRelationship?.supplier?.name}</h5>
                                        <Space size={12} align="center" className="mt-2">
                                            <Space size={4} align="center">
                                                <Icon name="location" className="fz-10" />
                                                <span className="fz-12">1.3 ml</span>
                                            </Space>{' '}
                                            <a href="tel:0121 724 0447" className="link fz-14">
                                                {tradingRelationship?.supplier?.phone}
                                            </a>
                                        </Space>

                                        <p className="mt-2">
                                            {tradingRelationship?.supplier?.description}
                                        </p>
                                    </div>
                                    <div id="mapRef" className="mt-3 map-scrool">
                                        <h5 className="h-5 text-center">Your service area</h5>
                                        <div className="mt-2 service-area-map">
                                            { tradingRelationship?.supplier && tradingRelationship?.supplier?.latitude ? (
                                                <GoogleMapReact
                                                    options={{
                                                        styles: MAP_STYLES,
                                                    }}
                                                    bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
                                                    defaultCenter={{ lat: parseFloat(tradingRelationship?.supplier?.latitude), lng: parseFloat(tradingRelationship?.supplier?.longitude) }}
                                                    defaultZoom={10}
                                                    yesIWantToUseGoogleMapApiInternals
                                                    onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)} />
                                            ) : ''}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-5 text-center">
                            <SupplierViewPage />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </Layout>
    );

}
